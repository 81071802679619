var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
const user = namespace('user');
const popup = {
    id: 0,
    text: '<p>Bitte beachten Sie Ihre neue Veranlagungsgruppe und deren Bankverbindung aufgrund der neuen Fondsstruktur.</p>',
    title: 'Vorschreibung 2023'
};
let CookiesInfoBox = class CookiesInfoBox extends Vue {
    constructor() {
        super(...arguments);
        this.isClosed = false;
        this.popup = popup;
    }
    get isShown() {
        return this.isUserLoggedIn && !this.isClosed;
    }
    get storedPopups() {
        let storedPopups = {};
        try {
            storedPopups = JSON.parse(sessionStorage.getItem('popups') || '{}') || {};
        }
        catch (e) { }
        return storedPopups;
    }
    close() {
        if (this.user && this.user.id) {
            if (!this.storedPopups[this.user.id]) {
                this.storedPopups[this.user.id] = {};
            }
            this.storedPopups[this.user.id][this.popup.id] = true;
            sessionStorage.setItem('popups', JSON.stringify(this.storedPopups));
        }
        this.isClosed = true;
    }
    /*
     * Lifecycle HOOKS
     */
    mounted() {
        if (this.user && this.user.id) {
            if (this.storedPopups[this.user.id]) {
                this.isClosed = !!this.storedPopups[this.user.id][this.popup.id];
            }
        }
    }
};
__decorate([
    user.Getter('canAccess')
], CookiesInfoBox.prototype, "isUserLoggedIn", void 0);
__decorate([
    user.State
], CookiesInfoBox.prototype, "user", void 0);
CookiesInfoBox = __decorate([
    Component
], CookiesInfoBox);
export default CookiesInfoBox;
