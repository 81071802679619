export default class Document {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f;
        this.title = '';
        this.download_url = '';
        this.download_filename = '';
        this.id = '';
        this.is_unread = false;
        this.related_property_1 = '';
        this.creation_date = data.creation_date ? new Date(data.creation_date) : this.creation_date;
        this.download_url = (_a = data.download_url) !== null && _a !== void 0 ? _a : this.download_url;
        this.download_filename = (_b = data.download_filename) !== null && _b !== void 0 ? _b : this.download_filename;
        this.id = (_c = data.id) !== null && _c !== void 0 ? _c : this.id;
        this.is_unread = (_d = data.is_unread) !== null && _d !== void 0 ? _d : this.is_unread;
        this.title = (_e = data.title) !== null && _e !== void 0 ? _e : this.title;
        this.related_property_1 = (_f = data.related_property_1) !== null && _f !== void 0 ? _f : this.related_property_1;
    }
    get date() {
        return this.creation_date;
    }
    get url() {
        return this.download_url;
    }
}
export var DocumentAreaType;
(function (DocumentAreaType) {
    DocumentAreaType["DASHBOARD_ACCOUNT_INFO"] = "dashboard:account:info";
    DocumentAreaType["DASHBOARD_ACCOUNT_REPORT"] = "dashboard:account:report";
    DocumentAreaType["DASHBOARD_NO_ACCOUNT_AREA1"] = "dashboard:no_account:area1";
    DocumentAreaType["DASHBOARD_NO_ACCOUNT_AREA2"] = "dashboard:no_account:area2";
    DocumentAreaType["ACCOUNT_EMPTY"] = "account:empty";
    DocumentAreaType["TAB"] = "documents:tab";
})(DocumentAreaType || (DocumentAreaType = {}));
export class DocumentArea {
    constructor(data) {
        var _a, _b, _c, _d, _e;
        this.area = DocumentAreaType.TAB;
        this.id = '';
        this.name = '';
        this.slug = '';
        this.sorting = 0;
        this.documents = [];
        this.area = (_a = data.area) !== null && _a !== void 0 ? _a : this.area;
        this.id = (_b = data.id) !== null && _b !== void 0 ? _b : this.id;
        this.name = (_c = data.name) !== null && _c !== void 0 ? _c : this.name;
        this.slug = (_d = data.slug) !== null && _d !== void 0 ? _d : this.slug;
        this.sorting = (_e = data.sorting) !== null && _e !== void 0 ? _e : this.sorting;
    }
    get countUnreadDocuments() {
        return this.documents.filter(document => document.is_unread).length;
    }
}
