import { appStore } from '~/utils/store-accessor';
const maintenancePageMiddleware = ({ error, route }) => {
    if (route.meta) {
        let maintenanceType = '';
        for (let i = 0; i < route.meta.length; i++) {
            if (route.meta[i].maintenanceType) {
                maintenanceType = route.meta[i].maintenanceType;
                break;
            }
        }
        if (!maintenanceType) {
            return;
        }
        if (appStore.maintenance && appStore.maintenance[maintenanceType].active) {
            error({ statusCode: 503, message: 'MAINTENANCE' });
        }
    }
};
export default maintenancePageMiddleware;
