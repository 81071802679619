var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Cookies from 'js-cookie';
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
const app = namespace('app');
const DAYS_IN_YEAR = 365;
const HASH_OPEN = 'open-cookiebox';
let CookiesInfoBox = class CookiesInfoBox extends Vue {
    constructor() {
        super(...arguments);
        this.cookiesAccepted = false;
        this.cookiesClosed = false;
    }
    /*
     * METHODS
     */
    accept() {
        Cookies.set('cookiesAccepted', '1', { expires: DAYS_IN_YEAR });
        Cookies.set('cookiesClosed', '1', { expires: DAYS_IN_YEAR });
        this.cookiesAccepted = true;
        this.cookiesClosed = true;
    }
    close() {
        Cookies.remove('cookiesAccepted');
        Cookies.set('cookiesClosed', '1', { expires: DAYS_IN_YEAR });
        this.cookiesAccepted = false;
        this.cookiesClosed = true;
    }
    handleDocumentClick(e) {
        const target = e.target;
        if (target && target.href) {
            const hash = target.href.split('#')[1];
            if (hash && hash.toLowerCase() === HASH_OPEN) {
                e.preventDefault();
                this.cookiesClosed = false;
            }
        }
    }
    disableScripts() {
        if (this.$matomo) {
            this.$matomo.setConsent(false);
        }
    }
    enableScripts() {
        if (this.$matomo) {
            this.$matomo.setConsent();
        }
    }
    /*
     * WATCHERS
     */
    onCookiesAcceptedChanged(accepted) {
        if (accepted) {
            this.enableScripts();
        }
        else {
            this.disableScripts();
        }
    }
    /*
     * Lifecycle HOOKS
     */
    mounted() {
        this.cookiesAccepted = Cookies.get('cookiesAccepted') === '1';
        this.cookiesClosed = Cookies.get('cookiesClosed') === '1';
        document.addEventListener('click', this.handleDocumentClick);
    }
    beforeDestroy() {
        document.removeEventListener('click', this.handleDocumentClick);
    }
};
__decorate([
    app.State
], CookiesInfoBox.prototype, "globalPages", void 0);
__decorate([
    app.Getter
], CookiesInfoBox.prototype, "portal", void 0);
__decorate([
    Watch('cookiesAccepted')
], CookiesInfoBox.prototype, "onCookiesAcceptedChanged", null);
CookiesInfoBox = __decorate([
    Component
], CookiesInfoBox);
export default CookiesInfoBox;
