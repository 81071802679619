import { PATHS } from '~/router';
import { userStore } from '~/store';
const authentificatedMiddleware = ({ redirect }) => {
    if (userStore.canAccess) {
        return redirect(PATHS.HOME);
    }
    else if (userStore.userExists && userStore.isOldSystemUser) {
        return redirect(PATHS.AUTH.REGISTER.UPDATE);
    }
};
export default authentificatedMiddleware;
