const PATHS = {
    ACCOUNT: {
        INDEX: '/account'
    },
    AUTH: {
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        REGISTER: {
            INDEX: '/auth/registration',
            UPDATE: '/auth/registration/update',
            VERIFY: '/auth/registration/verify'
        },
        RESET: {
            PASSWORD: {
                INDEX: '/auth/reset/password',
                VERIFY: '/auth/reset/password/verify'
            },
            USERNAME: '/auth/reset/username',
            USER: '/auth/reset/user'
        },
        SSO: {
            LOGIN: '/auth/sso/login',
            VERIFY: '/auth/sso/verify'
        }
    },
    CONTACT: '/kontakt-hilfe',
    DOCUMENTS: {
        AREAS: {
            KONTONACHRICHTEN: '/documents/kontonachrichten',
            VERANLAGUNG: '/documents/veranlagung',
            VORSCHREIBUNGEN: '/documents/vorschreibungen',
            ZAHLUNGSBESTATIGUNGEN: '/documents/zahlungsbestatigungen'
        },
        DETAIL: '/documents/detail',
        INDEX: '/documents'
    },
    HOME: '/',
    MESSAGES: {
        DETAIL: '/messages/detail',
        INDEX: '/messages',
        INBOX: '/messages/inbox',
        SENT: '/messages/sent',
        CREATE: '/messages/create'
    },
    PROFILE: {
        INDEX: '/profile',
        SETTINGS: {
            INDEX: '/profile/settings',
            DELETE: '/profile/settings/delete',
            EMAIL: '/profile/settings/email',
            PASSWORD: '/profile/settings/password'
        }
    },
    REQUESTS: {
        DETAIL: '/requests/detail',
        INDEX: '/requests',
        NEW: '/requests/new',
        TYPE: {
            DEATH_COMPENSATION: '/requests/new/death-compensation'
        },
        SUBMITTED: '/requests/submitted'
    }
};
const API_PATHS = {
    AUTH: {
        IMPERSONATE: '/auth/impersonate',
        LOGIN: '/auth/login',
        LOGOUT: '/auth/logout',
        ME: '/auth/me',
        REFRESH: '/auth/refresh',
        REGISTER: {
            PARSE_TOKEN: '/users/registration/parse-registration-token/{token}',
            STEP1: '/users/registration/step1',
            STEP2: '/users/registration/step2',
            UPDATE: '/users/registration/update',
            UPDATE_SSO: '/users/registration/update/sso-first-time-login',
            VERIFY: '/users/verify'
        },
        RESET: {
            PASSWORD: {
                INDEX: '/users/forgot/password',
                VERIFY: 'users/forgot/password/reset'
            },
            USERNAME: '/users/forgot/username',
            USER: '/users/reset'
        },
        SSO: {
            LOGIN: '/auth/sso/login'
        },
        T2T: '/auth/t2t',
        T2T_PREPARE: '/auth/prepare-t2t-login'
    },
    DOCUMENTS: {
        AREAS: '/document/areas',
        AREA_DOCUMENTS: '/user/area-documents',
        INDEX: '/user/documents'
    },
    INITIAL_DATA: '/initial-data',
    MESSAGE: {
        ADJACENCY: '/message/:id/adjacency',
        CREATE: '/message/create',
        DELETE: '/message/:id/delete/',
        DOWNLOAD_ATTACHMENT: '/message/:id/download-attachment',
        DOWNLOAD_ALL_ATTACHMENTS: '/message/:id/download-all-attachment',
        INBOX: '/message/inbox',
        INDEX: '/message',
        SENT: '/message/sent'
    },
    PAGES: '/pages',
    PROFILE: {
        CHANGE: {
            DOCUMENTS_VIA_POST: '/users/profile/toggle-documents-via-post',
            EMAIL: '/users/profile/change-email',
            PASSWORD: '/users/profile/change-password',
            SETTINGS: '/users/profile/change-settings'
        },
        DELETE: '/users/profile/delete'
    },
    REQUESTS: {
        AVAILABLE: '/user/requests/available',
        DOWNLOAD_ATTACHMENT: '/request/:id/download-attachment',
        DOWNLOAD_DEADLINE: '/request/:type/download-deadline-ical',
        NEW: {
            DEATH_COMPENSATION: '/request/new-death-compensation-request',
            DISPOSAL: '/request/new-disposal-request',
            EXEMPTION: '/request/new-exemption-request',
            INCOME_REDUCTION: '/request/new-income-reduction-request',
            REGISTRATION_REDUCTION: '/request/new-registration-reduction-request',
            RETIREMENT_CONTRIBUTION_EXEMPTION: '/request/new-retirement-contribution-exemption-request',
            RETIREMENT_WITHDRAW: '/request/new-retirement-withdraw-request',
            SECOND_YEAR_REGISTRATION_REDUCTION: '/request/new-2nd-year-registration-reduction-request',
            SEPA: '/request/new-sepa-request',
            VRG_CHANGE: '/request/new-vrg-change-request'
        },
        SUBMITTED: '/user/requests'
    },
    SETTINGS: '/settings',
    USER: '/user'
};
const PORTALS = {
    RAK: {
        // SSO: process.env.NODE_ENV === 'development' ? 'http://oerak.von.gd/nc/mitglieder/zum-login/' : 'https://www.rechtsanwaelte.at/nc/mitglieder/zum-login/'
        SSO: 'http://oerak.von.gd/nc/mitglieder/zum-login/'
    }
};
function extendRoutes(routes, resolve) {
    const customRoutes = [
        {
            path: '/profile',
            children: [
                {
                    name: 'profile-settings-delete',
                    path: 'settings/delete',
                    component: resolve(__dirname, 'pages/profile/settings/delete.vue'),
                    chunkName: 'pages/profile/settings/delete'
                },
                {
                    name: 'profile-settings-password',
                    path: 'settings/password',
                    component: resolve(__dirname, 'pages/profile/settings/password.vue'),
                    chunkName: 'pages/profile/settings/password'
                },
                {
                    name: 'profile-settings-email',
                    path: 'settings/email',
                    component: resolve(__dirname, 'pages/profile/settings/email.vue'),
                    chunkName: 'pages/profile/settings/email'
                }
            ]
        },
        {
            path: '/documents',
            children: [
                {
                    name: 'documents-detail-id',
                    path: 'detail/:id',
                    component: resolve(__dirname, 'pages/documents/detail/_id/index.vue'),
                    chunkName: 'pages/documents/detail/_id/index'
                }
            ]
        },
        {
            path: '/requests',
            children: [
                {
                    path: 'new',
                    children: [
                        {
                            name: 'requests-new-type',
                            path: ':type?',
                            component: resolve(__dirname, 'pages/requests/new/_type.vue'),
                            chunkName: 'pages/requests/new/_type'
                        }
                    ]
                },
                {
                    name: 'requests-detail-id',
                    path: 'detail/:id',
                    component: resolve(__dirname, 'pages/requests/detail/_id/index.vue'),
                    chunkName: 'pages/requests/detail/_id/index'
                }
            ]
        },
        {
            path: '/messages',
            children: [
                {
                    name: 'messages-detail-id',
                    path: 'detail/:id',
                    component: resolve(__dirname, 'pages/messages/detail/_id/index.vue'),
                    chunkName: 'pages/messages/detail/_id/index'
                }
            ]
        }
    ];
    // Remove custom routes from automatically generated routes
    const removeCustomRoute = (customRoute, routes) => {
        var _a, _b;
        for (const route of routes) {
            if (route.path === customRoute.path) {
                if (((_a = route.children) === null || _a === void 0 ? void 0 : _a.length) && ((_b = customRoute.children) === null || _b === void 0 ? void 0 : _b.length)) {
                    for (const child of customRoute.children) {
                        removeCustomRoute(child, route.children);
                    }
                    route.children = route.children.filter((child) => {
                        var _a;
                        return !((_a = customRoute.children) === null || _a === void 0 ? void 0 : _a.some((customChild) => {
                            return customChild.path === child.path && !customChild.children;
                        }));
                    });
                }
            }
        }
    };
    for (const customRoute of customRoutes) {
        removeCustomRoute(customRoute, routes);
    }
    // Add custom routes to routes
    const addCustomRoute = (customRoute, path = '') => {
        var _a;
        if ((_a = customRoute.children) === null || _a === void 0 ? void 0 : _a.length) {
            for (const child of customRoute.children) {
                addCustomRoute(child, path + '/' + customRoute.path.replace(/^\//, ''));
            }
        }
        else {
            routes.push({
                name: customRoute.name,
                path: `${path}/${customRoute.path.replace(/^\//, '')}`,
                component: customRoute.component,
                chunkName: customRoute.chunkName
            });
        }
    };
    for (const customRoute of customRoutes) {
        addCustomRoute(customRoute);
    }
}
export { PATHS, API_PATHS, PORTALS, extendRoutes };
