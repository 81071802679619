/* eslint-disable import/no-mutable-exports */
import { getModule } from 'vuex-module-decorators';
import app from '~/store/app';
import documents from '~/store/documents';
import messages from '~/store/messages';
import requests from '~/store/requests';
import user from '~/store/user';
let $axios;
let i18n;
let $notifier;
let appStore;
let documentsStore;
let messagesStore;
let requestsStore;
let userStore;
function initializeAxios(axiosInstance) {
    $axios = axiosInstance;
}
function initializeI18n(i18nInstance) {
    i18n = i18nInstance;
}
function initializeNotifier(notifierInstance) {
    $notifier = notifierInstance;
}
function initialiseStores(store) {
    appStore = getModule(app, store);
    documentsStore = getModule(documents, store);
    messagesStore = getModule(messages, store);
    requestsStore = getModule(requests, store);
    userStore = getModule(user, store);
}
export { initializeAxios, initializeI18n, initializeNotifier, initialiseStores, appStore, documentsStore, messagesStore, requestsStore, userStore, $axios, $notifier, i18n };
