import download from 'downloadjs';
import { API_PATHS, PATHS } from '~/router';
import { $axios, $notifier, i18n } from '~/utils/store-accessor';
export const REQUEST_DEADLINE_ALWAYS = 'always';
export const REQUEST_DEADLINE_OFFSET = 60;
export default class Request {
    constructor(data) {
        var _a, _b, _c;
        this.deadline = REQUEST_DEADLINE_ALWAYS;
        this.enabled = true;
        this.id = '';
        this.introtext = '';
        this.recently_defined = false;
        this.title = '';
        this.teaser = {
            title: '',
            introtext: ''
        };
        this.type = 'default';
        this.detailComponent = () => null;
        this.formComponent = () => import('~/components/forms/form.vue');
        let deadline;
        if (data.deadline) {
            deadline = new Date(data.deadline);
            if (isNaN(deadline.getTime())) {
                deadline = undefined;
            }
        }
        this.created_at = data.created_at ? new Date(data.created_at) : this.created_at;
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
        this.deadline = deadline !== null && deadline !== void 0 ? deadline : this.deadline;
        this.recently_defined = (_b = data.recently_defined) !== null && _b !== void 0 ? _b : this.recently_defined;
        this.id = (_c = data.id) !== null && _c !== void 0 ? _c : this.id;
    }
    get daysToDeadline() {
        if (this.deadline instanceof Date) {
            const now = new Date();
            const diffTime = Math.abs(this.deadline.getTime() - now.getTime());
            return Math.ceil(diffTime / (1000 * 3600 * 24));
        }
        return NaN;
    }
    get date() {
        return this.created_at;
    }
    get hasAttachments() {
        return !!this.data.documents && this.data.documents.length > 0;
    }
    get hasDeadline() {
        return this.deadline !== REQUEST_DEADLINE_ALWAYS;
    }
    get to() {
        return PATHS.REQUESTS.NEW + '/' + this.type;
    }
    downloadICal() {
        $axios.get(API_PATHS.REQUESTS.DOWNLOAD_DEADLINE.replace(':type', this.type.replace(/-/g, '_')), {
            responseType: 'blob'
        }).then((response) => {
            const contentType = response.headers['content-type'];
            download(response.data, this.type, contentType);
        }).catch((e) => {
            $notifier.showMessage({
                content: i18n.t('ERROR_MESSAGE'),
                type: 'error'
            });
            throw e;
        });
    }
}
