var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
import PageLoading from '~/components/global/PageLoading.vue';
import { PATHS } from '~/router';
import Sidebar from '~/components/global/Sidebar.vue';
const app = namespace('app');
let Default = class Default extends Vue {
    constructor() {
        super(...arguments);
        this.PATHS = PATHS;
        this.isSidebarVisible = null;
    }
    head() {
        require('~/assets/sass/style-vuetify.scss');
        require('~/assets/sass/style.scss');
        if (this.portal.key === 'bpk') {
            require('~/assets/sass/portals/bpk/style-vuetify.scss');
            require('~/assets/sass/portals/bpk/style.scss');
        }
        if (this.portal.key === 'pk') {
            require('~/assets/sass/portals/pk/style-vuetify.scss');
            require('~/assets/sass/portals/pk/style.scss');
        }
        return {};
    }
};
__decorate([
    app.State
], Default.prototype, "isLoaded", void 0);
__decorate([
    app.Getter
], Default.prototype, "portal", void 0);
Default = __decorate([
    Component({
        components: {
            PageLoading,
            Sidebar
        }
    })
], Default);
export default Default;
