var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from 'nuxt-property-decorator';
import { VCard, VIcon } from 'vuetify/lib';
import Sizeable from 'vuetify/src/mixins/sizeable';
let TextCard = class TextCard extends VCard {
    get classes() {
        return {
            'text-card': true,
            ...VCard.options.computed.classes.call(this),
            ...this.sizeableClasses
        };
    }
};
__decorate([
    Prop({ default: true })
], TextCard.prototype, "outlined", void 0);
TextCard = __decorate([
    Component({
        methods: {
            genIcon() {
                if (!this.isClickable) {
                    return null;
                }
                return this.$createElement('div', {
                    staticClass: 'text-card__icon'
                }, [this.$createElement(VIcon, undefined, '$vuetify.icons.arrow_forward')]);
            }
        },
        mixins: [Sizeable],
        render(h) {
            const { tag, data } = this.generateRouteLink();
            data.style = this.styles;
            if (this.isClickable) {
                data.attrs = data.attrs || {};
                data.attrs.tabindex = 0;
            }
            return h(tag, this.setBackgroundColor(this.color, data), [this.genProgress(), this.$slots.default, this.genIcon()]);
        }
    })
], TextCard);
export default TextCard;
