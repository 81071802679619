import Cookies from 'js-cookie';
import { appStore, userStore, initialiseStores } from '~/utils/store-accessor';
const initializer = (store) => initialiseStores(store);
function initUserToken() {
    const token = Cookies.get('token');
    if (token && !userStore.token) {
        userStore.setToken({ token });
    }
    setInterval(() => {
        if (userStore.token !== Cookies.get('token')) {
            userStore.setToken({ token: Cookies.get('token') || '' });
        }
    }, 1000);
}
async function initAppPortal() {
    await appStore.initPortal(window.location.host);
}
export const actions = {
    async nuxtClientInit() {
        initUserToken();
        await initAppPortal()
            .catch(() => {
            this.app.context.error({ statusCode: 404, message: 'Domain not be found' });
        });
    }
};
export const plugins = [initializer];
export * from '~/utils/store-accessor';
