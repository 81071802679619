import Request from '~/classes/Request';
export default class RAKRetirementWithdrawRequest extends Request {
    constructor(data) {
        var _a;
        super(data);
        this.introtext = '<p>Die Altersrente wird frühestens ab Vollendung des <strong>65. Lebensjahres</strong> gewährt und erfolgt ab dem im Antrag gewählten <strong>Monatsersten</strong>. Ein Verzicht auf die Ausübung der rechtsanwaltlichen Tätigkeit ist nicht verpflichtend.</p>';
        this.title = 'Antrag auf Altersrente';
        this.teaser = {
            introtext: 'Ab Vollendung des 65. Lebensjahres haben Sie die Möglichkeit eine Altersrente mit oder ohne Teilabfindung zu beziehen.',
            title: 'Altersrente'
        };
        this.type = 'retirement-withdraw';
        this.detailComponent = () => import('~/portals/rak/components/requests/retirement-withdraw.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/retirement-withdraw.vue');
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
    }
}
