var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { requestFactoryByType } from '~/classes/requests/factory';
import { $axios, $notifier } from '~/utils/store-accessor';
import { API_PATHS } from '~/router';
/*
 * Requests store
 */
let RequestsStore = class RequestsStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.available = [];
        this.submitted = [];
    }
    setAvailable(requests) {
        this.available = requests;
    }
    setSubmitted(requests) {
        this.submitted = requests;
    }
    async fetchAvailableRequests() {
        await $axios.get(API_PATHS.REQUESTS.AVAILABLE)
            .then((response) => {
            let requests = [];
            if (response.data) {
                if (Array.isArray(response.data)) {
                    requests = response.data.map((requestType) => {
                        return requestFactoryByType(requestType);
                    });
                }
                else {
                    requests = Object.keys(response.data).map((requestType) => {
                        return requestFactoryByType(requestType, response.data[requestType]);
                    });
                }
            }
            this.setAvailable(requests);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
            throw error;
        });
    }
    async getAvailableRequests() {
        await this.fetchAvailableRequests();
        return this.available;
    }
    async getAvailableRequestByType(type) {
        return await this.getAvailableRequests().then((requests) => {
            return requests.find(request => request.type === type);
        });
    }
    async fetchSubmitted() {
        await $axios.get(API_PATHS.REQUESTS.SUBMITTED)
            .then((response) => {
            let requests = [];
            if (response.data && response.data.length) {
                requests = response.data.map((request) => {
                    return requestFactoryByType(request.type, request);
                });
            }
            this.setSubmitted(requests);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
            throw error;
        });
    }
    async getSubmitted() {
        await this.fetchSubmitted();
        return this.submitted;
    }
    async getSubmittedById(id) {
        return await this.getSubmitted().then((requests) => {
            return requests.find(request => request.id === id);
        });
    }
    reset() {
        this.setAvailable([]);
        this.setSubmitted([]);
        return Promise.resolve();
    }
};
__decorate([
    Mutation
], RequestsStore.prototype, "setAvailable", null);
__decorate([
    Mutation
], RequestsStore.prototype, "setSubmitted", null);
__decorate([
    Action
], RequestsStore.prototype, "fetchAvailableRequests", null);
__decorate([
    Action
], RequestsStore.prototype, "getAvailableRequests", null);
__decorate([
    Action
], RequestsStore.prototype, "getAvailableRequestByType", null);
__decorate([
    Action
], RequestsStore.prototype, "fetchSubmitted", null);
__decorate([
    Action
], RequestsStore.prototype, "getSubmitted", null);
__decorate([
    Action
], RequestsStore.prototype, "getSubmittedById", null);
__decorate([
    Action
], RequestsStore.prototype, "reset", null);
RequestsStore = __decorate([
    Module({
        name: 'requests',
        stateFactory: true,
        namespaced: true
    })
], RequestsStore);
export default RequestsStore;
