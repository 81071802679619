import User from '~/classes/User';
export class Attachment {
    // constructor(data: Partial<Attachment>) {
    //   this.title = data.title ?? this.title;
    // }
    constructor(title) {
        this.title = '';
        this.title = title;
    }
}
class MessageConversation {
    constructor(data) {
        var _a, _b;
        this.has_attachments = false;
        this.is_unread = false;
        this.date = data.date ? new Date(data.date) : this.date;
        this.has_attachments = (_a = data.has_attachments) !== null && _a !== void 0 ? _a : this.has_attachments;
        this.is_unread = (_b = data.is_unread) !== null && _b !== void 0 ? _b : this.is_unread;
    }
}
export default class Message {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.attachments = [];
        this.be_user_id = null;
        this.body = '';
        this.conversation = null;
        this.id = '';
        this.is_unread = false;
        this.root_ancestor = null;
        this.root_id = '';
        this.root_subject = '';
        this.subject = '';
        this.user = null;
        this.attachments = (_a = data.attachments) !== null && _a !== void 0 ? _a : this.attachments;
        this.be_user_id = (_b = data.be_user_id) !== null && _b !== void 0 ? _b : this.be_user_id;
        this.body = (_c = data.body) !== null && _c !== void 0 ? _c : this.body;
        this.created_at = data.created_at ? new Date(data.created_at) : this.created_at;
        this.id = (_d = data.id) !== null && _d !== void 0 ? _d : this.id;
        this.is_unread = (_e = data.is_unread) !== null && _e !== void 0 ? _e : this.is_unread;
        this.root_id = (_f = data.root_id) !== null && _f !== void 0 ? _f : this.root_id;
        this.root_subject = (_g = data.root_subject) !== null && _g !== void 0 ? _g : this.root_subject;
        this.subject = (_h = data.subject) !== null && _h !== void 0 ? _h : this.subject;
        if (Array.isArray(data.attachments)) {
            this.attachments = [];
            data.attachments.forEach((attachment) => {
                this.attachments.push(new Attachment(attachment));
            });
        }
        if (data.conversation) {
            this.conversation = new MessageConversation(data.conversation);
        }
        if (data.root_ancestor) {
            this.root_ancestor = new Message(data.root_ancestor);
        }
        if (data.user) {
            this.user = new User();
            this.user.setData(data.user);
        }
    }
    get date() {
        return this.created_at;
    }
    get hasAttachments() {
        return this.attachments.length > 0;
    }
    get isUserMessage() {
        return !this.be_user_id;
    }
}
