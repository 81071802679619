const middleware = {}

middleware['auth/redirect/authentificated'] = require('../middleware/auth/redirect/authentificated.ts')
middleware['auth/redirect/authentificated'] = middleware['auth/redirect/authentificated'].default || middleware['auth/redirect/authentificated']

middleware['auth/redirect/guest'] = require('../middleware/auth/redirect/guest.ts')
middleware['auth/redirect/guest'] = middleware['auth/redirect/guest'].default || middleware['auth/redirect/guest']

middleware['maintenance/global'] = require('../middleware/maintenance/global.ts')
middleware['maintenance/global'] = middleware['maintenance/global'].default || middleware['maintenance/global']

middleware['maintenance/page'] = require('../middleware/maintenance/page.ts')
middleware['maintenance/page'] = middleware['maintenance/page'].default || middleware['maintenance/page']

middleware['portal'] = require('../middleware/portal.ts')
middleware['portal'] = middleware['portal'].default || middleware['portal']

middleware['user'] = require('../middleware/user.ts')
middleware['user'] = middleware['user'].default || middleware['user']

export default middleware
