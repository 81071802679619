import Request from '~/classes/Request';
import { i18n, userStore } from '~/utils/store-accessor';
export default class RAKSecondYearRegistrationReductionRequest extends Request {
    constructor(data) {
        var _a, _b;
        super(data);
        this.introtext = '<p>Sie erklären, vor Ablauf der ersten 12 Kalendermonate nach Ersteintragung eine Ermäßigung des Beitrags auf <strong>mindestens 20%</strong> für das 13. – 24. Monat in Anspruch zu nehmen.</p>';
        this.title = 'Erklärung zur Ermäßigung des Beitrags wegen Ersteintragung 13.–24. Monat';
        this.teaser = {
            introtext: 'Eine Ermäßigung des Beitrags auf mindestens 20 % kann für das 13.-24. Monat ab Ersteintragung in Anspruch genommen werden.',
            title: 'Ermäßigung des Beitrags wegen Ersteintragung 13.–24. Monat'
        };
        this.type = 'second-year-registration-reduction';
        this.detailComponent = () => import('~/portals/rak/components/requests/second-year-registration-reduction.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/second-year-registration-reduction.vue');
        const availableAccounts = ((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.availableAccounts) || [];
        if (availableAccounts.length) {
            this.introtext += `<p>Der <strong>Beitrag</strong> zur Versorgungseinrichtung Teil B beträgt für das Jahr ${availableAccounts[0].jahr_ordentlicher_jahresbeitrag} <strong>${i18n.n(availableAccounts[0].ordentlicher_jahresbeitrag, 'currency')}</strong> p.a..</p>`;
        }
        this.data = (_b = data.data) !== null && _b !== void 0 ? _b : {};
    }
}
