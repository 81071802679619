import Request from '~/classes/Request';
export default class RAKRetirementContributionExemptionRequest extends Request {
    constructor(data) {
        var _a;
        super(data);
        this.introtext = '<p>Versicherte, die das 65. Lebensjahr vollendet haben, sind auf Antrag von der Beitragspflicht zu befreien. Diese Befreiung gilt ab dem nächsten Monatsersten bzw. dem im Antrag angegebenen Zeitpunkt <strong>bis zur Vollendung des 70. Lebensjahres</strong> (reguläres Ende der Beitragspflicht).<br>Ab dem Zeitpunkt der Gewährung einer Altersrente erlischt die Beitragspflicht automatisch.</p>';
        this.title = 'Beitragsbefreiung wegen Vollendung des 65. Lebensjahres';
        this.teaser = {
            introtext: 'Mit Vollendung des 65. Lebensjahres haben Sie die Möglichkeit, sich von der Beitragspflicht befreien zu lassen.',
            title: 'Beitragsbefreiung wegen Vollendung des 65. Lebensjahres'
        };
        this.type = 'retirement-contribution-exemption';
        this.detailComponent = () => import('~/portals/rak/components/requests/retirement-contribution-exemption.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/retirement-contribution-exemption.vue');
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
    }
}
