var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'nuxt-property-decorator';
let PageTitle = class PageTitle extends Vue {
};
__decorate([
    Prop({ type: String, default: '' })
], PageTitle.prototype, "name", void 0);
__decorate([
    Prop({ type: String, default: '' })
], PageTitle.prototype, "icon", void 0);
__decorate([
    Prop({ type: String, default: '' })
], PageTitle.prototype, "title", void 0);
__decorate([
    Prop([Boolean])
], PageTitle.prototype, "showBackButton", void 0);
__decorate([
    Prop({ type: [String], default: '' })
], PageTitle.prototype, "backButtonLink", void 0);
__decorate([
    Prop({ type: String, default: '' })
], PageTitle.prototype, "backButtonText", void 0);
PageTitle = __decorate([
    Component
], PageTitle);
export default PageTitle;
