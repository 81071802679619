var Gender;
(function (Gender) {
    Gender["FEMALE"] = "f";
    Gender["MALE"] = "m";
    Gender["OTHER"] = "o";
})(Gender || (Gender = {}));
var TransactionType;
(function (TransactionType) {
    TransactionType["DEBIT"] = "dept";
    TransactionType["GARANTEED_CAPITAL"] = "gcap";
    TransactionType["HEADER"] = "hdr";
    TransactionType["ITEM"] = "itm";
    TransactionType["SUB_ITEM"] = "subitm";
    TransactionType["SUMMARY"] = "sum";
    TransactionType["SUMMARY_PART"] = "sumprt";
})(TransactionType || (TransactionType = {}));
var TransactionValueType;
(function (TransactionValueType) {
    TransactionValueType["CURRENCY"] = "cur";
    TransactionValueType["PERCENTAGE"] = "perc";
    TransactionValueType["TEXT"] = "txt";
})(TransactionValueType || (TransactionValueType = {}));
export class Transaction {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.bTp = TransactionValueType.TEXT;
        this.bez = '';
        this.bt1 = '';
        this.bt1lbl = '';
        this.bt2 = '';
        this.bt2lbl = '';
        this.bt3 = '';
        this.bt3lbl = '';
        this.dt = '';
        this.grp = '';
        this.srt = 0;
        this.tTp = TransactionType.ITEM;
        this.blk = '';
        this.bTp = (_a = data.bTp) !== null && _a !== void 0 ? _a : this.bTp;
        this.bez = (_b = data.bez) !== null && _b !== void 0 ? _b : this.bez;
        this.bt1 = (_c = data.bt1) !== null && _c !== void 0 ? _c : this.bt1;
        this.bt1lbl = (_d = data.bt1lbl) !== null && _d !== void 0 ? _d : this.bt1lbl;
        this.bt2 = (_e = data.bt2) !== null && _e !== void 0 ? _e : this.bt2;
        this.bt2lbl = (_f = data.bt2lbl) !== null && _f !== void 0 ? _f : this.bt2lbl;
        this.bt3 = (_g = data.bt3) !== null && _g !== void 0 ? _g : this.bt3;
        this.bt3lbl = (_h = data.bt3lbl) !== null && _h !== void 0 ? _h : this.bt3lbl;
        this.dt = (_j = data.dt) !== null && _j !== void 0 ? _j : this.dt;
        this.grp = (_k = data.grp) !== null && _k !== void 0 ? _k : this.grp;
        this.srt = (_l = data.srt) !== null && _l !== void 0 ? _l : this.srt;
        this.tTp = data.tTp ? data.tTp : this.tTp;
        this.blk = (_m = data.blk) !== null && _m !== void 0 ? _m : this.blk;
    }
    isCurrency() {
        return this.bTp.toLowerCase() === TransactionValueType.CURRENCY;
    }
    isTableHead() {
        return this.tTp.toLowerCase() === TransactionType.HEADER;
    }
}
export var AccountDisplayStatus;
(function (AccountDisplayStatus) {
    AccountDisplayStatus[AccountDisplayStatus["OK"] = 0] = "OK";
    AccountDisplayStatus[AccountDisplayStatus["ERROR"] = 1] = "ERROR";
    AccountDisplayStatus[AccountDisplayStatus["NONE"] = 2] = "NONE";
})(AccountDisplayStatus || (AccountDisplayStatus = {}));
export var AccountStatus;
(function (AccountStatus) {
    AccountStatus["NONE"] = "";
    AccountStatus["ACTIVE"] = "aktiv";
    AccountStatus["DEACTIVATED"] = "leistungsbezieher";
    AccountStatus["DEACTIVATION_IN_PROCESS"] = "leistungsbez_Abfert";
    AccountStatus["FREE"] = "beitragsfrei";
    AccountStatus["ENCLOSED"] = "abgeschlossen";
})(AccountStatus || (AccountStatus = {}));
export class Account {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        this.anwartschaft_jahresbeginn = 0;
        this.anwartschaft_jahresbeginn_ag = 0;
        this.anwartschaft_jahresbeginn_an = 0;
        this.anwartschaft_stichtag = 0;
        this.applikations_nummer = 0;
        this.dienstgeber_name = '';
        this.dienstgeber_nummer = 0;
        this.fehlbetrag = 0;
        this.id = '';
        this.is_disposable = false;
        this.jahr_ordentlicher_jahresbeitrag = 0;
        this.konto_anzeige_status = AccountDisplayStatus.OK;
        this.konto_status = AccountStatus.NONE;
        this.ordentlicher_jahresbeitrag = 0;
        this.rak_saldo = 0;
        this.schwankungsrueckstellung = 0;
        this.verfuegungs_code = 0;
        this.verfuegbares_kapital = 0;
        this.vg_nummer = '';
        this.vrg_nummer = 0;
        this.vr_t = '';
        this.transactions = [];
        this.anwartschaft_jahresbeginn = (_a = data.anwartschaft_jahresbeginn) !== null && _a !== void 0 ? _a : this.anwartschaft_jahresbeginn;
        this.anwartschaft_jahresbeginn_ag = (_b = data.anwartschaft_jahresbeginn_ag) !== null && _b !== void 0 ? _b : this.anwartschaft_jahresbeginn_ag;
        this.anwartschaft_jahresbeginn_an = (_c = data.anwartschaft_jahresbeginn_an) !== null && _c !== void 0 ? _c : this.anwartschaft_jahresbeginn_an;
        this.anwartschaft_stichtag = (_d = data.anwartschaft_stichtag) !== null && _d !== void 0 ? _d : this.anwartschaft_stichtag;
        this.applikations_nummer = (_e = data.applikations_nummer) !== null && _e !== void 0 ? _e : this.applikations_nummer;
        this.datum_jahresbeginn = data.datum_jahresbeginn ? new Date(data.datum_jahresbeginn) : this.datum_jahresbeginn;
        this.datum_rentenzahlungsbeginn = data.datum_rentenzahlungsbeginn ? new Date(data.datum_rentenzahlungsbeginn) : this.datum_rentenzahlungsbeginn;
        this.datum_stichtag = data.datum_stichtag ? new Date(data.datum_stichtag) : this.datum_stichtag;
        this.dienstgeber_name = (_f = data.dienstgeber_name) !== null && _f !== void 0 ? _f : this.dienstgeber_name;
        this.dienstgeber_nummer = (_g = data.dienstgeber_nummer) !== null && _g !== void 0 ? _g : this.dienstgeber_nummer;
        this.fehlbetrag = (_h = data.fehlbetrag) !== null && _h !== void 0 ? _h : this.fehlbetrag;
        this.id = (_j = data.id) !== null && _j !== void 0 ? _j : this.id;
        this.is_disposable = (_k = data.is_disposable) !== null && _k !== void 0 ? _k : this.is_disposable;
        this.jahr_ordentlicher_jahresbeitrag = (_l = data.jahr_ordentlicher_jahresbeitrag) !== null && _l !== void 0 ? _l : this.jahr_ordentlicher_jahresbeitrag;
        this.konto_anzeige_status = (_m = data.konto_anzeige_status) !== null && _m !== void 0 ? _m : this.konto_anzeige_status;
        this.konto_status = (_o = data.konto_status) !== null && _o !== void 0 ? _o : this.konto_status;
        this.ordentlicher_jahresbeitrag = (_p = data.ordentlicher_jahresbeitrag) !== null && _p !== void 0 ? _p : this.ordentlicher_jahresbeitrag;
        this.rak_saldo = (_q = data.rak_saldo) !== null && _q !== void 0 ? _q : this.rak_saldo;
        this.schwankungsrueckstellung = (_r = data.schwankungsrueckstellung) !== null && _r !== void 0 ? _r : this.schwankungsrueckstellung;
        this.verfuegungs_code = (_s = data.verfuegungs_code) !== null && _s !== void 0 ? _s : this.verfuegungs_code;
        this.verfuegbares_kapital = (_t = data.verfuegbares_kapital) !== null && _t !== void 0 ? _t : this.verfuegbares_kapital;
        this.vg_nummer = (_u = data.vg_nummer) !== null && _u !== void 0 ? _u : this.vg_nummer;
        this.vrg_nummer = (_v = data.vrg_nummer) !== null && _v !== void 0 ? _v : this.vrg_nummer;
        this.vr_t = (_w = data.vr_t) !== null && _w !== void 0 ? _w : this.vr_t;
        if (Array.isArray(data.transactions)) {
            this.transactions = [];
            data.transactions.forEach((transaction) => {
                this.transactions.push(new Transaction(transaction));
            });
            this.transactions.sort((trx1, trx2) => trx1.srt > trx2.srt ? 1 : -1);
        }
    }
    get canShowTransaction() {
        return this.konto_anzeige_status === AccountDisplayStatus.OK && !!this.transactions.length;
    }
    get groupedTransactions() {
        const transactions = [];
        this.transactions.forEach((transaction, index) => {
            if (!transactions.length || !transaction.grp || transaction.grp !== this.transactions[index - 1].grp) {
                transactions.push({
                    transaction,
                    children: []
                });
            }
            else {
                transactions[transactions.length - 1].children.push(transaction);
            }
        });
        return transactions;
    }
    get isDeactivated() {
        return this.konto_status === AccountStatus.DEACTIVATED || this.konto_status === AccountStatus.DEACTIVATION_IN_PROCESS;
    }
}
var LOGIN_TYPE;
(function (LOGIN_TYPE) {
    LOGIN_TYPE[LOGIN_TYPE["NO_LOGIN"] = 0] = "NO_LOGIN";
    LOGIN_TYPE[LOGIN_TYPE["SSO"] = 1] = "SSO";
    LOGIN_TYPE[LOGIN_TYPE["DIRECT"] = 2] = "DIRECT";
})(LOGIN_TYPE || (LOGIN_TYPE = {}));
export var RelativePersonType;
(function (RelativePersonType) {
    RelativePersonType["NATURAL"] = "natP";
    RelativePersonType["JURIDICAL"] = "jurP";
})(RelativePersonType || (RelativePersonType = {}));
var RelativePersonOrder;
(function (RelativePersonOrder) {
    RelativePersonOrder["PRIMARY"] = "hp";
    RelativePersonOrder["SECONDARY"] = "ep";
})(RelativePersonOrder || (RelativePersonOrder = {}));
export class RelativePerson {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        this.btArt = null;
        this.btTyp = null;
        this.dtG = null;
        this.jPNr = '';
        this.lnd = '';
        this.nV = '';
        this.ort = '';
        this.pN = '';
        this.plz = '';
        this.str = '';
        this.ttH = '';
        this.ttV = '';
        this.btArt = (_a = data.btArt) !== null && _a !== void 0 ? _a : this.btArt;
        this.btTyp = (_b = data.btTyp) !== null && _b !== void 0 ? _b : this.btTyp;
        this.dtG = data.dtG ? new Date(data.dtG) : this.dtG;
        this.jPNr = (_c = data.jPNr) !== null && _c !== void 0 ? _c : this.jPNr;
        this.lnd = (_d = data.lnd) !== null && _d !== void 0 ? _d : this.lnd;
        this.nV = (_e = data.nV) !== null && _e !== void 0 ? _e : this.nV;
        this.ort = (_f = data.ort) !== null && _f !== void 0 ? _f : this.ort;
        this.pN = (_g = data.pN) !== null && _g !== void 0 ? _g : this.pN;
        this.plz = (_h = data.plz) !== null && _h !== void 0 ? _h : this.plz;
        this.str = (_j = data.str) !== null && _j !== void 0 ? _j : this.str;
        this.ttH = (_k = data.ttH) !== null && _k !== void 0 ? _k : this.ttH;
        this.ttV = (_l = data.ttV) !== null && _l !== void 0 ? _l : this.ttV;
    }
    get name() {
        let name = `${this.nV} ${this.pN}`;
        if (this.ttV) {
            name = `${this.ttV} ${name}`;
        }
        if (this.ttH) {
            name = `${name} ${this.ttH}`;
        }
        return name;
    }
    get address() {
        let address = this.ort;
        if (this.plz) {
            address = `${this.plz} ${address}`;
        }
        if (this.str) {
            address = `${address}, ${this.str}`;
        }
        if (this.lnd) {
            address = `${address} (${this.lnd})`;
        }
        return address;
    }
    get birthDate() {
        return this.dtG;
    }
    get identifier() {
        return this.jPNr;
    }
    get order() {
        return this.btTyp;
    }
    get type() {
        return this.btArt;
    }
}
export class UserSettings {
    constructor(data = {}) {
        var _a;
        this.notifiable = {
            document_areas: {},
            messages: {
                new: false
            }
        };
        this.notifiable = (_a = data.notifiable) !== null && _a !== void 0 ? _a : this.notifiable;
    }
}
export default class User {
    constructor() {
        this.accounts = [];
        this.bic = '';
        this.birthday = undefined;
        this.city = '';
        this.country = '';
        this.documents_via_post = false;
        this.email = '';
        this.first_name = '';
        this.gender = Gender.OTHER;
        this.has_verified_email = false;
        this.iban = '';
        this.id = '';
        this.last_name = '';
        this.login_type = LOGIN_TYPE.NO_LOGIN;
        this.loginable_to = [];
        this.number_of_unread_messages = 0;
        this.old_system_user = false;
        this.relative_persons = {};
        this.rj_code = '';
        this.ssn = '';
        this.street = '';
        this.title_after = '';
        this.title_before = '';
        this.zip = '';
        this.settings = new UserSettings();
    }
    get activeAccount() {
        return this.accounts.find(account => account.konto_status === AccountStatus.ACTIVE);
    }
    get availableAccounts() {
        return this.accounts.filter(account => account.konto_status === AccountStatus.ACTIVE && account.konto_anzeige_status === AccountDisplayStatus.OK);
    }
    hasAccount() {
        var _a;
        return ((_a = this.accounts) === null || _a === void 0 ? void 0 : _a.length) > 0;
    }
    hasAccountDisplayStatus(status) {
        return this.accounts.some(account => account.konto_anzeige_status === status);
    }
    hasAccountStatus(status) {
        return this.accounts.some(account => account.konto_status === status);
    }
    hasAllAccountsVerfuegungsCode(verfuegungs_code) {
        return !this.accounts.some(account => account.verfuegungs_code !== verfuegungs_code);
    }
    hasAllAccountsDeactivated() {
        return !this.accounts.some(account => !account.isDeactivated);
    }
    hasRelativePersons() {
        return !!this.relative_persons.primary || !!this.relative_persons.secondary;
    }
    setAccounts(accounts) {
        this.accounts = [];
        accounts === null || accounts === void 0 ? void 0 : accounts.forEach((account) => {
            this.accounts.push(new Account(account));
        });
    }
    setData(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        this.bic = (_a = data.bic) !== null && _a !== void 0 ? _a : this.bic;
        this.birthday = data.birthday ? new Date(data.birthday) : this.birthday;
        this.city = (_b = data.city) !== null && _b !== void 0 ? _b : this.city;
        this.country = (_c = data.country) !== null && _c !== void 0 ? _c : this.country;
        this.documents_via_post = (_d = data.documents_via_post) !== null && _d !== void 0 ? _d : this.documents_via_post;
        this.email = (_e = data.email) !== null && _e !== void 0 ? _e : this.email;
        this.first_name = (_f = data.first_name) !== null && _f !== void 0 ? _f : this.first_name;
        this.gender = (_g = data.gender) !== null && _g !== void 0 ? _g : this.gender;
        this.has_verified_email = (_h = data.has_verified_email) !== null && _h !== void 0 ? _h : this.has_verified_email;
        this.iban = (_j = data.iban) !== null && _j !== void 0 ? _j : this.iban;
        this.id = (_k = data.id) !== null && _k !== void 0 ? _k : this.id;
        this.last_name = (_l = data.last_name) !== null && _l !== void 0 ? _l : this.last_name;
        this.login_type = (_m = data.login_type) !== null && _m !== void 0 ? _m : this.login_type;
        this.loginable_to = (_o = data.loginable_to) !== null && _o !== void 0 ? _o : this.loginable_to;
        this.number_of_unread_messages = (_p = data.number_of_unread_messages) !== null && _p !== void 0 ? _p : this.number_of_unread_messages;
        this.old_system_user = (_q = data.old_system_user) !== null && _q !== void 0 ? _q : this.old_system_user;
        this.rj_code = (_r = data.rj_code) !== null && _r !== void 0 ? _r : this.rj_code;
        this.ssn = (_s = data.ssn) !== null && _s !== void 0 ? _s : this.ssn;
        this.street = (_t = data.street) !== null && _t !== void 0 ? _t : this.street;
        this.title_after = (_u = data.title_after) !== null && _u !== void 0 ? _u : this.title_after;
        this.title_before = (_v = data.title_before) !== null && _v !== void 0 ? _v : this.title_before;
        this.zip = (_w = data.zip) !== null && _w !== void 0 ? _w : this.zip;
        if (data.accounts) {
            this.setAccounts(data.accounts);
        }
        if (Array.isArray(data.relative_persons)) {
            this.setRelativePersons(data.relative_persons);
        }
        if (data.settings) {
            this.setSettings(data.settings);
        }
    }
    setSettings(settings) {
        this.settings = new UserSettings(settings);
    }
    setRelativePersons(persons) {
        const primaryRelativePerson = persons.find(person => person.btTyp === RelativePersonOrder.PRIMARY);
        const secondaryRelativePerson = persons.find(person => person.btTyp === RelativePersonOrder.SECONDARY);
        if (primaryRelativePerson) {
            this.relative_persons.primary = new RelativePerson(primaryRelativePerson);
        }
        if (secondaryRelativePerson) {
            this.relative_persons.secondary = new RelativePerson(secondaryRelativePerson);
        }
    }
    get accountsNewestDatumStichtag() {
        return this.accounts
            .filter(account => account.konto_anzeige_status === AccountDisplayStatus.OK)
            .reduce((previousDate, account) => {
            if (account.datum_stichtag) {
                if (!previousDate || account.datum_stichtag > previousDate) {
                    previousDate = account.datum_stichtag;
                }
            }
            return previousDate;
        }, undefined);
    }
    get accountsSumAnwartschaftStichtag() {
        return this.accounts
            .filter(account => account.konto_anzeige_status === AccountDisplayStatus.OK)
            .reduce((previousValue, account) => {
            return previousValue + account.anwartschaft_stichtag;
        }, 0);
    }
    get chamberNumber() {
        let chamber = 0;
        for (let i = 0; i < this.rj_code.length; i++) {
            const c = this.rj_code[i];
            if (c >= '0' && c <= '9') {
                chamber = parseInt(c);
                break;
            }
        }
        return chamber;
    }
    get full_name() {
        let fullName = `${this.first_name} ${this.last_name}`;
        fullName = this.title_after ? `${fullName}, ${this.title_after}` : fullName;
        fullName = this.title_before ? `${this.title_before} ${fullName}` : fullName;
        return fullName;
    }
    get ssn_formatted() {
        return `${this.ssn.substring(0, 4)} ${this.ssn.substring(4)}`;
    }
    get isOldSystemUser() {
        return this.old_system_user;
    }
    get isSSOUser() {
        return this.login_type === LOGIN_TYPE.SSO;
    }
}
