import { API_PATHS } from '~/router';
import { initializeAxios } from '~/utils/store-accessor';
import { appStore, userStore } from '~/store';
const SENTRY_IGNORE_ERRORS = {
    [API_PATHS.AUTH.LOGIN]: [403],
    [API_PATHS.AUTH.REGISTER.VERIFY]: [403],
    [API_PATHS.AUTH.RESET.PASSWORD.INDEX]: [404],
    [API_PATHS.AUTH.REGISTER.STEP1]: [404] // Ignore 404 errors on registration step 1
};
export default ({ $axios, $sentry }) => {
    $axios.onError((error) => {
        var _a;
        // Check if the error response status code and URL are in the SENTRY_IGNORE_ERRORS
        if (error.config && error.config.url && error.config.url in SENTRY_IGNORE_ERRORS) {
            const ignoredStatusCodes = SENTRY_IGNORE_ERRORS[error.config.url];
            if (error.response && error.response.status && ignoredStatusCodes.includes(error.response.status)) {
                return; // Skip handling ignored errors
            }
        }
        if ((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message) {
            error.message += `\nBE: ${error.response.data.message}`;
        }
        $sentry.captureException(error);
    });
    // Request interceptor
    $axios.interceptors.request.use((request) => {
        const token = userStore.token;
        const portal = appStore.portal;
        if (token) {
            request.headers.common.Authorization = `Bearer ${token}`;
        }
        if (portal) {
            request.headers.common['X-Tenant'] = portal.key;
        }
        return request;
    });
    initializeAxios($axios);
};
