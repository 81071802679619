import { appStore } from '~/store';
import { initializeNotifier } from '~/utils/store-accessor';
const Notifier = (context, inject) => {
    inject('notifier', {
        showMessage({ content = '', type = '' }) {
            appStore.showSnackbar({ content, type });
        }
    });
    initializeNotifier(context.$notifier);
};
export default Notifier;
