import Request from '~/classes/Request';
/**
 * Disposal types
 */
export var VK_DISPOSAL_REQUEST_TYPE;
(function (VK_DISPOSAL_REQUEST_TYPE) {
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["WEITERVERANLAGUNG"] = 1] = "WEITERVERANLAGUNG";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["BANKVERBINDUNG"] = 2] = "BANKVERBINDUNG";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["POSTANWEISUNG"] = 3] = "POSTANWEISUNG";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["UEBERTRAGUNG"] = 4] = "UEBERTRAGUNG";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["VERSICHERUNGSUNTERNEHMEN_BKV"] = 5] = "VERSICHERUNGSUNTERNEHMEN_BKV";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["VERSICHERUNGSUNTERNEHMEN_PZV"] = 6] = "VERSICHERUNGSUNTERNEHMEN_PZV";
    VK_DISPOSAL_REQUEST_TYPE[VK_DISPOSAL_REQUEST_TYPE["PENSIONSKASSE"] = 7] = "PENSIONSKASSE";
})(VK_DISPOSAL_REQUEST_TYPE || (VK_DISPOSAL_REQUEST_TYPE = {}));
export default class VKDisposalRequest extends Request {
    constructor(data) {
        var _a;
        super(data);
        this.introtext = '<p>Sie haben die Möglichkeit, den Verfügungsbetrag in der BONUS Vorsorgekasse verantwortungsvoll weiter zu veranlagen oder an die aktuelle Vorsorgekasse, eine Pensionskasse oder an ein Versicherungsunternehmen zu übertragen oder als Kapitalbetrag auszahlen zu lassen.</p>';
        this.title = 'Antrag auf Verfügung';
        this.teaser = {
            title: 'Antrag auf Verfügung',
            introtext: 'Sie können eine Auszahlung bzw. Übertragung Ihres Guthabens veranlassen'
        };
        this.type = 'disposal';
        this.detailComponent = () => import('~/portals/vk/components/requests/disposal.vue');
        this.formComponent = () => import('~/portals/vk/components/forms/requests/disposal.vue');
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
    }
}
