import { extend, localize, setInteractionMode } from 'vee-validate';
import { confirmed, email, ext, length, max, max_value, min_value, numeric, oneOf, required, size } from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import localeDe from '../locale/de.json';
const dict = {
    custom: {
        fields: {
            consent: {
                required: 'Bitte stimmen Sie zu!'
            },
            delete_confirmation: {
                required: 'Sie müssen zustimmen'
            },
            documents: {
                required: 'Bitte laden Sie die benötigte/n Dokument/e hoch.'
            },
            electronic_communication: {
                required: 'Sie müssen der elektronischen Kommunikation zustimmen'
            },
            'request.accounts': {
                required: 'Bitte wählen Sie den Verfügungsbetrag aus'
            },
            'request.disposal.type.primary': {
                required: 'Bitte wählen Sie die Art der Verfügung aus'
            },
            'request.disposal.type.secondary': {
                required: 'Bitte wählen Sie die Art der Versicherung aus'
            },
            'request.consent': {
                required: 'Bitte bestätigen Sie Ihre Eingabe'
            },
            terms_of_use: {
                required: 'Sie müssen unseren Nutzungsbedingungen zustimmen'
            }
        },
        messages: {
            password: localeDe.user.password.hint,
            ext: 'Bitte laden Sie einen gültigen Dateityp hoch.',
            size(field, params) {
                return `Die Daten muss kleiner als ${params.size / 1000}MB sein.`;
            }
        }
    }
};
localize('de', de);
localize('de', dict.custom);
setInteractionMode('lazy');
const rules = { confirmed, email, ext, length, max, max_value, min_value, numeric, oneOf, required, size };
for (const rule in rules) {
    extend(rule, {
        ...rules[rule]
    });
}
extend('password', {
    validate: (value) => {
        let constraints = 0;
        constraints += value.match(/[A-Z]/) ? 1 : 0;
        constraints += value.match(/[a-z]/) ? 1 : 0;
        constraints += value.match(/[0-9]/) ? 1 : 0;
        constraints += value.match(/[\W_]/) ? 1 : 0;
        return value.length >= 8 &&
            value.length <= 64 && constraints >= 3;
    },
    message: ''
});
extend('max_count', {
    validate: (value, params) => {
        if (!Array.isArray(value)) {
            return false;
        }
        return value.length <= params.length;
    },
    message: 'Die maximale Anzahl der Dokumente ({length}) ist erreicht.',
    params: [
        {
            name: 'length',
            cast(value) {
                return Number(value);
            }
        }
    ]
});
extend('date', {
    validate: (value) => {
        const dateParts = value.split('.').map(part => parseInt(part));
        const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
        return date.getFullYear() === dateParts[2] && date.getMonth() + 1 === dateParts[1];
    },
    message: '{_field_} ist ungültig'
});
extend('alpha_num_extended', {
    validate: (value) => {
        if (!value) {
            return true;
        }
        return /^[\w\s\p{L}.-]+$/u.test(String(value));
    },
    message: 'Zulässige Zeichen sind nur: Buchstaben, Leerzeichen, Bindestrich (-) und Punkt (.)'
});
