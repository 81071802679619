export default {
    breakpoint: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 600,
            sm: 960,
            md: 1200,
            lg: 1620
        },
        scrollBarWidth: 0
    },
    icons: {
        values: {
            account_balance: 'M18.33,4A2.77,2.77,0,0,1,21,6.86h0V17.14A2.77,2.77,0,0,1,18.33,20H5.67A2.77,2.77,0,0,1,3,17.14H3V6.86A2.77,2.77,0,0,1,5.67,4H18.33Zm0,1.14H5.67A1.65,1.65,0,0,0,4.12,6.86h0V17.14a1.65,1.65,0,0,0,1.55,1.72H18.33a1.65,1.65,0,0,0,1.55-1.72h0V6.86a1.65,1.65,0,0,0-1.55-1.72ZM17.06,15.43a.57.57,0,0,1,.1,1.13H14.81a.56.56,0,0,1-.56-.57.57.57,0,0,1,.46-.56h2.35Zm-4.36,0c.24,0,.42.25.42.57a.54.54,0,0,1-.34.56h-6c-.24,0-.42-.26-.42-.57a.53.53,0,0,1,.34-.56h6Zm4.36-4.57a.57.57,0,0,1,.1,1.13H14.81a.57.57,0,0,1-.56-.57.56.56,0,0,1,.46-.56h2.35Zm-4.36,0c.24,0,.42.26.42.58a.54.54,0,0,1-.34.56h-6c-.24,0-.42-.26-.42-.57a.56.56,0,0,1,.34-.57h6Zm4.36-3.42a.57.57,0,0,1,.1,1.13H14.81A.56.56,0,0,1,14.25,8a.57.57,0,0,1,.46-.56h2.35Zm-4.36,0c.24,0,.42.25.42.57a.54.54,0,0,1-.34.56h-6c-.24,0-.42-.26-.42-.57a.53.53,0,0,1,.34-.56h6Z',
            arrow_back: 'M9.16,6.58a.63.63,0,0,0-.9,0h0l-5.07,5-.07.09a.65.65,0,0,0,.07.83h0L8.1,17.42l.09.08A.66.66,0,0,0,9,17.42H9l.07-.09A.64.64,0,0,0,9,16.47H9l-3.9-3.86H20.46a.64.64,0,0,0-.12-1.28H5.3L9.16,7.48l.08-.09A.64.64,0,0,0,9.16,6.58Z',
            arrow_down: 'M19.25,6.69a1,1,0,0,1,1.6,1.17L20.76,8l-8,9.33a1,1,0,0,1-1.4.11l-.1-.11L3.24,8a1,1,0,0,1,.11-1.4,1,1,0,0,1,1.29,0l.11.11L12,15.14Z',
            arrow_forward: 'M14.83,6.54a.63.63,0,0,1,.9,0h0l5.08,5.07.07.09a.65.65,0,0,1-.07.83h0l-4.92,4.92-.09.08a.65.65,0,0,1-.83-.08h0l-.08-.09a.63.63,0,0,1,.08-.82h0l3.9-3.9H3.54A.65.65,0,0,1,3,11.89a.64.64,0,0,1,.65-.54H18.71L14.83,7.44l-.08-.09A.64.64,0,0,1,14.83,6.54Z',
            attachment: 'M15.44,3a4,4,0,0,1,4,4,3.93,3.93,0,0,1-1,2.63l-.16.18-7,6.94a2.28,2.28,0,0,1-3.88-1.6,2.28,2.28,0,0,1,.53-1.46l.13-.15,6.37-6.33a.57.57,0,0,1,.8,0,.56.56,0,0,1,0,.79L8.86,14.31h0a1.11,1.11,0,0,0-.34.81,1.15,1.15,0,0,0,1.15,1.14,1.1,1.1,0,0,0,.7-.24l.12-.1,7-6.94a2.81,2.81,0,0,0,.84-2,2.86,2.86,0,0,0-4.72-2.16L13.41,5,6.23,12.11a4.52,4.52,0,0,0-1.34,3.21,4.58,4.58,0,0,0,7.62,3.4l.19-.18L19.28,12a.56.56,0,0,1,.79,0,.57.57,0,0,1,0,.8L13.5,19.34h0a5.71,5.71,0,0,1-9.74-4,5.66,5.66,0,0,1,1.47-3.79l.2-.22,7.19-7.15A4,4,0,0,1,15.44,3Z',
            book_open: 'M11.91,21h.32a1.35,1.35,0,0,0,.36-.07l7.45-2a1.28,1.28,0,0,0,.68-.46,1.24,1.24,0,0,0,.28-.78V4a.89.89,0,0,0-.39-.78.87.87,0,0,0-.9-.16l-7.41,2a1.34,1.34,0,0,1-.4,0,1.34,1.34,0,0,1-.4,0l-7.19-2a.87.87,0,0,0-.9.16A.89.89,0,0,0,3,4V17.65a1.22,1.22,0,0,0,.28.78,1.32,1.32,0,0,0,.68.48l7.2,2,.33.06A3,3,0,0,0,11.91,21Zm-.47-1.2-7.2-2a.18.18,0,0,1-.08-.07.17.17,0,0,1,0-.1V4.18l7.08,2h.24Zm1.12,0V6.14h0l7.31-2V17.67a.12.12,0,0,1-.05.1.2.2,0,0,1-.09.07Zm1.8-10.32h.07l3.95-1.12a.51.51,0,0,0,.32-.27.53.53,0,0,0-.2-.76.64.64,0,0,0-.43-.06l-4,1.11a.56.56,0,0,0-.32.27.58.58,0,0,0-.06.43.48.48,0,0,0,.21.29.6.6,0,0,0,.34.11Zm-4.58,0a.62.62,0,0,0,.34-.13.54.54,0,0,0,.21-.3.64.64,0,0,0-.06-.43.55.55,0,0,0-.33-.27L6,7.23a.67.67,0,0,0-.44.06.53.53,0,0,0-.2.76.55.55,0,0,0,.33.27l3.92,1.1H9.7Zm4.58,3.37h.07l3.94-1.11a.55.55,0,0,0,.33-.27.53.53,0,0,0,.06-.41.58.58,0,0,0-.7-.4l-3.95,1.1a.67.67,0,0,0-.31.29.55.55,0,0,0-.06.42.49.49,0,0,0,.21.3.59.59,0,0,0,.34.1Zm-4.58,0a.79.79,0,0,0,.33-.1.49.49,0,0,0,.21-.31.62.62,0,0,0-.06-.42.61.61,0,0,0-.33-.28L6,10.6a.59.59,0,0,0-.71.4.54.54,0,0,0,.07.41.51.51,0,0,0,.32.27l3.93,1.11a.11.11,0,0,0,.08,0Zm4.58,3.37h.07l3.94-1.11a.55.55,0,0,0,.33-.27.58.58,0,0,0,.06-.41.55.55,0,0,0-.27-.34.59.59,0,0,0-.43-.07l-3.95,1.11a.6.6,0,0,0-.32.27.59.59,0,0,0-.06.43.54.54,0,0,0,.21.3.6.6,0,0,0,.34.11Zm-4.58,0a.57.57,0,0,0,.33-.11.47.47,0,0,0,.21-.3.64.64,0,0,0-.06-.43.59.59,0,0,0-.33-.27L6,14a.56.56,0,0,0-.44.06.55.55,0,0,0-.27.34.54.54,0,0,0,.07.41.51.51,0,0,0,.32.27l3.93,1.11H9.7Z',
            burger: 'M3.56,8.06H18.19a.56.56,0,0,0,0-1.12H3.56a.56.56,0,1,0,0,1.12ZM20.44,11.44H3.56a.56.56,0,1,0,0,1.12H20.44a.56.56,0,0,0,0-1.12ZM15.94,15.94H3.56a.56.56,0,1,0,0,1.12H15.94a.56.56,0,0,0,0-1.12Z',
            calendar_export: 'M16.5,3a.64.64,0,0,1,.64.64v.65h1.29A2.57,2.57,0,0,1,21,6.86V18.43A2.57,2.57,0,0,1,18.43,21H5.57A2.57,2.57,0,0,1,3,18.43V6.86A2.57,2.57,0,0,1,5.57,4.29H6.86V3.64a.64.64,0,0,1,1.28,0v.65h7.72V3.64A.64.64,0,0,1,16.5,3ZM6.86,5.57H5.57A1.3,1.3,0,0,0,4.29,6.71V18.43a1.29,1.29,0,0,0,1.13,1.28h13a1.29,1.29,0,0,0,1.28-1.13V6.86a1.28,1.28,0,0,0-1.13-1.28H17.14v.64a.64.64,0,1,1-1.28,0V5.57H8.14v.64a.64.64,0,1,1-1.28,0ZM16.5,17.14a.65.65,0,0,1,0,1.29h-9a.65.65,0,0,1,0-1.29ZM12,6.86a.63.63,0,0,1,.63.52v6.28L15.4,10.9a.64.64,0,0,1,.82-.07l.09.07a.65.65,0,0,1,.08.82l-.08.09-3.86,3.86a.64.64,0,0,1-.82.07l-.08-.07L7.69,11.81a.65.65,0,0,1,0-.91.64.64,0,0,1,.82-.07l.09.07,2.76,2.76V7.5A.63.63,0,0,1,12,6.86Z',
            check: 'M20.12,5.9a.52.52,0,0,1,.73,0,.53.53,0,0,1,.06.67l-.06.07L9.54,18.1a.52.52,0,0,1-.66.06l-.07-.06L3.15,12.37a.53.53,0,0,1,0-.74.52.52,0,0,1,.66-.06l.07.06L9.17,17Z',
            checkboxOn: 'M19.88,2.09h-15a2.5,2.5,0,0,0-2.5,2.5v15a2.5,2.5,0,0,0,2.5,2.5h15a2.5,2.5,0,0,0,2.5-2.5v-15A2.5,2.5,0,0,0,19.88,2.09Zm-9.5,16-5-5,2-2,3,3,7-7,2,2Z',
            checkboxOff: 'M19.5,22H4.5A2.5,2.5,0,0,1,2,19.5V4.5A2.5,2.5,0,0,1,4.5,2h15A2.5,2.5,0,0,1,22,4.5v15A2.5,2.5,0,0,1,19.5,22ZM4.5,3A1.5,1.5,0,0,0,3,4.5v15A1.5,1.5,0,0,0,4.5,21h15A1.5,1.5,0,0,0,21,19.5V4.5A1.5,1.5,0,0,0,19.5,3Z',
            check_circle_outline: 'M12,3a9,9,0,1,1-9,9A9,9,0,0,1,12,3Zm0,1.12A7.88,7.88,0,1,0,19.88,12,7.88,7.88,0,0,0,12,4.12Zm4.7,5.3a.57.57,0,0,1,.06.72l-.06.08-5.36,5.35a.56.56,0,0,1-.72.06l-.08-.06L8.19,13.22a.56.56,0,0,1,0-.79.56.56,0,0,1,.72-.07l.07.07,2,2,5-5A.57.57,0,0,1,16.7,9.42Z',
            close: 'M12.84,12l8-8a.59.59,0,0,0-.76-.9L20,3.17l-8,8-8-8-.08-.06a.59.59,0,0,0-.76.9l8,8-8,8a.59.59,0,0,0,.76.9L4,20.83l8-8,8,8,.08.06a.59.59,0,0,0,.76-.9Z',
            close_small: 'M19.85,21a1.12,1.12,0,0,1-.81-.34L3.33,4.93a1.13,1.13,0,0,1,0-1.6A1.15,1.15,0,0,1,5,3.34L20.67,19.07a1.13,1.13,0,0,1,0,1.6A1.15,1.15,0,0,1,19.85,21ZM4.15,21a1.15,1.15,0,0,1-.81-.33,1.13,1.13,0,0,1,0-1.6L19,3.34a1.15,1.15,0,0,1,1.62,0,1.13,1.13,0,0,1,0,1.6L5,20.66A1.12,1.12,0,0,1,4.15,21Z',
            complete: 'M19.52,5.74a.87.87,0,0,1,1.23,0,.88.88,0,0,1,.08,1.13l-.08.1L9.44,18.26a.87.87,0,0,1-1.12.08l-.1-.08-5-5a.87.87,0,0,1,0-1.23A.86.86,0,0,1,4.38,12l.1.08,4.35,4.34Z',
            delete: 'M14.62,3a1.81,1.81,0,0,1,1.87,1.53V6.38h3.94a.56.56,0,0,1,.56.56.57.57,0,0,1-.46.55H18.75V19.21A1.71,1.71,0,0,1,17.3,21H6.84a1.67,1.67,0,0,1-1.58-1.63V7.5H3.56A.56.56,0,0,1,3,6.94a.57.57,0,0,1,.46-.56h4V4.69A1.76,1.76,0,0,1,9.21,3h5.41Zm3,4.5H6.38V19.21a.63.63,0,0,0,.39.65l.07,0H17.16c.21,0,.41-.22.46-.55V7.5ZM13.69,9.75a.57.57,0,0,1,.55.46v7.41a.56.56,0,0,1-.56.57.57.57,0,0,1-.56-.46V10.31A.57.57,0,0,1,13.69,9.75Zm-3.38,0a.57.57,0,0,1,.56.46v7.41a.56.56,0,0,1-1.12.11V10.31A.56.56,0,0,1,10.31,9.75Zm4.31-5.63H9.37a.74.74,0,0,0-.74.48V6.38h6.76V4.69a.66.66,0,0,0-.63-.56Z',
            download: 'M19.93,15.93a.57.57,0,0,1,.56.46v1.79A2.82,2.82,0,0,1,17.85,21H6.33a2.82,2.82,0,0,1-2.82-2.63V16.49a.56.56,0,0,1,1.12-.1v1.79a1.71,1.71,0,0,0,1.54,1.69h11.5a1.69,1.69,0,0,0,1.69-1.52V16.49A.56.56,0,0,1,19.93,15.93ZM12,3a.57.57,0,0,1,.56.46V15.69l3.54-3.54.08-.06a.57.57,0,0,1,.72.06.57.57,0,0,1,0,.8h0l-4.51,4.5,0,0,0,0,0,0,0,0h0l0,0h0l-.06,0h-.23l-.06,0h0l-.05,0,0,0-.09-.07.06.05-.06-.05h0L7.07,13,7,12.87a.57.57,0,0,1,.06-.72h0l.08-.06a.59.59,0,0,1,.73.06h0l3.59,3.55V3.56A.56.56,0,0,1,12,3Z',
            dropdown: 'M3.29,7.29a1,1,0,0,1,1.28-.11l.14.11L12,14.59l7.29-7.3a1,1,0,0,1,1.28-.11l.14.11a1,1,0,0,1,.11,1.28l-.11.14-8,8a1,1,0,0,1-1.28.11l-.14-.11-8-8A1,1,0,0,1,3.29,7.29Z',
            edit: 'M11.18,5.43a.56.56,0,0,1,.56.56.57.57,0,0,1-.46.56H6.12A1.68,1.68,0,0,0,4.44,8.08V18.37A1.69,1.69,0,0,0,6,20.05H17.37a1.69,1.69,0,0,0,1.68-1.53V13.31a.56.56,0,0,1,1.11-.1v5.16a2.82,2.82,0,0,1-2.63,2.81H6.12a2.82,2.82,0,0,1-2.81-2.63V8.24a2.8,2.8,0,0,1,2.62-2.8h5.25Zm9.33-1.75.15.13L20.8,4a2.22,2.22,0,0,1,0,2.82l-.14.15-7.85,7.86a.71.71,0,0,1-.14.1l-.08,0L9.22,16.05a.56.56,0,0,1-.73-.63l0-.08L9.63,12a.71.71,0,0,1,.08-.15l.06-.07,7.85-7.92A2.13,2.13,0,0,1,20.51,3.68Zm-2,.84-.1.09-7.76,7.83-.73,2.18,2.18-.72,6.24-6.24-.72-.76a.55.55,0,0,1,0-.79.56.56,0,0,1,.72-.05l.07.07.7.73.73-.73A1.07,1.07,0,0,0,20,4.82L20,4.71l-.1-.11-.09-.08A1,1,0,0,0,18.52,4.52Z',
            email_outline: 'M18.19,5A2.76,2.76,0,0,1,21,7.69h0v8.62A2.76,2.76,0,0,1,18.19,19H5.81A2.76,2.76,0,0,1,3,16.31H3V7.69A2.76,2.76,0,0,1,5.81,5H18.19Zm1.69,4.13L12.26,13a.56.56,0,0,1-.42,0l-.1,0L4.12,9.13v7.18a1.65,1.65,0,0,0,1.53,1.61H18.19a1.65,1.65,0,0,0,1.69-1.61h0ZM18.19,6.08H5.81A1.65,1.65,0,0,0,4.12,7.69h0v.22l7.88,4,7.88-4V7.69a1.65,1.65,0,0,0-1.53-1.61Z',
            error: 'M12,3a9,9,0,1,1-9,9A9,9,0,0,1,12,3Zm0,1.12A7.88,7.88,0,1,0,19.88,12,7.88,7.88,0,0,0,12,4.12Zm-3.06,4L9,8.23l3.18,3.18,3.19-3.18.07-.07a.56.56,0,0,1,.72.07.56.56,0,0,1,0,.79h0L13,12.2l3.18,3.19a.56.56,0,0,1,0,.79.56.56,0,0,1-.72.07l-.07-.07L12.2,13,9,16.18l-.08.07a.56.56,0,0,1-.71-.86h0l3.18-3.19L8.23,9a.56.56,0,0,1,.71-.86Z',
            expand: 'M19.25,6.69a1,1,0,0,1,1.6,1.17L20.76,8l-8,9.33a1,1,0,0,1-1.4.11l-.1-.11L3.24,8a1,1,0,0,1,.11-1.4,1,1,0,0,1,1.29,0l.11.11L12,15.14Z',
            file: 'M15.44,3a4,4,0,0,1,4,4,3.93,3.93,0,0,1-1,2.63l-.16.18-7,6.94a2.28,2.28,0,0,1-3.88-1.6,2.28,2.28,0,0,1,.53-1.46l.13-.15,6.37-6.33a.57.57,0,0,1,.8,0,.56.56,0,0,1,0,.79L8.86,14.31h0a1.11,1.11,0,0,0-.34.81,1.15,1.15,0,0,0,1.15,1.14,1.1,1.1,0,0,0,.7-.24l.12-.1,7-6.94a2.81,2.81,0,0,0,.84-2,2.86,2.86,0,0,0-4.72-2.16L13.41,5,6.23,12.11a4.52,4.52,0,0,0-1.34,3.21,4.58,4.58,0,0,0,7.62,3.4l.19-.18L19.28,12a.56.56,0,0,1,.79,0,.57.57,0,0,1,0,.8L13.5,19.34h0a5.71,5.71,0,0,1-9.74-4,5.66,5.66,0,0,1,1.47-3.79l.2-.22,7.19-7.15A4,4,0,0,1,15.44,3Z',
            file_document_outline: 'M13.71,3h.13l.06,0,0,0,.05,0,0,0,.09.07-.06,0,0,0h0l5.71,5.63h0l0,0,0,0,0,0a.08.08,0,0,1,0,0l0,0a.64.64,0,0,1,0,.07v0a.31.31,0,0,1,0,.1h0v9A2.84,2.84,0,0,1,17.14,21H6.86A2.84,2.84,0,0,1,4,18.19H4V5.81A2.84,2.84,0,0,1,6.86,3h6.85Zm-.57,1.12H6.86A1.71,1.71,0,0,0,5.14,5.81h0V18.19a1.71,1.71,0,0,0,1.72,1.69H17.14a1.71,1.71,0,0,0,1.72-1.69h0V9.75H16a2.84,2.84,0,0,1-2.85-2.63v-3Zm.57,11.26a.57.57,0,0,1,.58.56.58.58,0,0,1-.47.55H8a.56.56,0,0,1-.57-.56.57.57,0,0,1,.47-.56h5.81ZM16,13.12a.56.56,0,0,1,.1,1.12H8a.56.56,0,0,1-.57-.56.57.57,0,0,1,.47-.56H16ZM11.43,9.75a.56.56,0,0,1,.1,1.12H8a.56.56,0,0,1-.1-1.11h3.53Zm2.86-4.83v2a1.7,1.7,0,0,0,1.54,1.68h2.22Z',
            help_outline: 'M12,3a9,9,0,1,1-9,9A9,9,0,0,1,12,3Zm0,1.12A7.88,7.88,0,1,0,19.88,12,7.88,7.88,0,0,0,12,4.12ZM12,15a1,1,0,0,1,1,1,.94.94,0,0,1-.28.7,1,1,0,0,1-1.4,0,.94.94,0,0,1-.28-.7,1,1,0,0,1,1-1Zm0-7.87a3.86,3.86,0,0,1,2.43.77,2.49,2.49,0,0,1,1,2,2.24,2.24,0,0,1-.49,1.48,4.8,4.8,0,0,1-1.09.88,5,5,0,0,0-.81.59,1.32,1.32,0,0,0-.32.85.44.44,0,0,1-.12.3.41.41,0,0,1-.3.12h-.65a.36.36,0,0,1-.21-.11.44.44,0,0,1-.12-.3,2.43,2.43,0,0,1,.49-1.55,5.6,5.6,0,0,1,1.09-.84,3.61,3.61,0,0,0,.77-.6A1.09,1.09,0,0,0,14,9.89a1.17,1.17,0,0,0-.49-1A2.25,2.25,0,0,0,12,8.48a2.65,2.65,0,0,0-1.21.25,2.77,2.77,0,0,0-.9.77.36.36,0,0,1-.25.14.35.35,0,0,1-.31-.07h0l-.46-.31L8.81,9.2A.39.39,0,0,1,8.7,9a.36.36,0,0,1,.07-.32,4.75,4.75,0,0,1,1.3-1.16A4.13,4.13,0,0,1,12,7.08Z',
            info_outline: 'M12,3a9,9,0,1,1-9,9A9,9,0,0,1,12,3Zm0,1.12A7.88,7.88,0,1,0,19.88,12,7.88,7.88,0,0,0,12,4.12ZM12.42,10h.07a.35.35,0,0,1,.23.12.39.39,0,0,1,.13.29h0v4.93h.48a.32.32,0,0,1,.24.11.41.41,0,0,1,.11.3h0v.35a.41.41,0,0,1-.11.23.42.42,0,0,1-.3.12h-2.6a.36.36,0,0,1-.24-.11.42.42,0,0,1-.12-.3h0v-.35a.4.4,0,0,1,.12-.23.38.38,0,0,1,.29-.12h.43V11.15h-.49a.42.42,0,0,1-.36-.42h0v-.34a.39.39,0,0,1,.12-.24.38.38,0,0,1,.29-.12h1.71ZM12,6.94a1.12,1.12,0,0,1,.81,1.89l0,0a1.08,1.08,0,0,1-1.54,0l0,0a1.08,1.08,0,0,1,0-1.55l0,0A1.1,1.1,0,0,1,12,6.94Z',
            login: 'M12.11,8.79a.57.57,0,0,1,.8,0h0l3.37,3.37.06.07h0a.62.62,0,0,1,.09.32.5.5,0,0,1-.06.24h0v.07l-.07.07.06-.07-.05.06h0l-3.37,3.37-.08.07a.57.57,0,0,1-.72-.07h0l-.07-.08a.58.58,0,0,1,.07-.72h0l2.41-2.41H4.65a.56.56,0,0,1-.45-.66A.55.55,0,0,1,4.53,12h10L12.11,9.59l-.06-.08A.57.57,0,0,1,12.11,8.79Zm6-5.79a1.67,1.67,0,0,1,1.67,1.52V19.31A1.68,1.68,0,0,1,18.3,21H8a1.69,1.69,0,0,1-1.68-1.52v-3a.57.57,0,0,1,1.12-.1v2.91a.56.56,0,0,0,.46.56H18.14a.57.57,0,0,0,.55-.46V4.69a.56.56,0,0,0-.44-.56H8a.57.57,0,0,0-.56.45v4a.57.57,0,0,1-.56.57.58.58,0,0,1-.56-.47v-4A1.68,1.68,0,0,1,7.84,3Z',
            logout: 'M19.87,12.49l0-.1,0-.08,0-.06,0-.05-3.4-3.41-.08-.06a.56.56,0,0,0-.79.78l.07.08L18,12H8a.57.57,0,0,0-.46.55.56.56,0,0,0,.56.56H18l-2.41,2.42-.07.08a.57.57,0,0,0,0,.64l.07.08.08.06a.57.57,0,0,0,.64,0l.08-.06L19.72,13l.05,0,0-.05,0-.07,0-.1a.5.5,0,0,0,0-.12ZM17.06,18.19a.57.57,0,0,0-.55.46v.66a.57.57,0,0,1-.46.56H5.81a.57.57,0,0,1-.55-.47V4.69a.57.57,0,0,1,.46-.56H15.94a.57.57,0,0,1,.55.47V6.38a.56.56,0,0,0,.56.56.57.57,0,0,0,.56-.46V4.69A1.68,1.68,0,0,0,16.1,3H5.81A1.69,1.69,0,0,0,4.13,4.52V19.31A1.69,1.69,0,0,0,5.65,21H15.94a1.69,1.69,0,0,0,1.68-1.53v-.72A.56.56,0,0,0,17.06,18.19Z',
            next: 'M14.83,6.55a.66.66,0,0,1,.92,0h0l5.06,5.07.08.09a.64.64,0,0,1-.08.82h0l-4.92,4.92-.08.07a.65.65,0,0,1-.83-.07h0l-.07-.09a.65.65,0,0,1,.07-.83h0l3.89-3.89H3.53a.65.65,0,0,1,.12-1.29H18.71L14.83,7.47l-.07-.09A.65.65,0,0,1,14.83,6.55Z',
            pencil_outline: 'M15.68,3.92a3.11,3.11,0,1,1,4.41,4.4h0L8.78,19.63a.53.53,0,0,1-.28.16h0L3.74,21A.59.59,0,0,1,3,20.26H3l1.19-4.7a.72.72,0,0,1,.15-.27h0Zm3.56.83a1.92,1.92,0,0,0-2.72,0h0L5.32,16l-.9,3.57,3.63-.9L18.23,8.5l-.77-.77a.6.6,0,0,1,.76-.91l.08.07.77.77.17-.18A1.92,1.92,0,0,0,19.47,5l-.11-.15Z',
            person_outline: 'M12,12A4.49,4.49,0,0,0,16.5,7.51h0A4.5,4.5,0,1,0,12,12Zm0-8A3.5,3.5,0,1,1,8.5,7.5,3.5,3.5,0,0,1,12,4ZM20.49,15.52a12.78,12.78,0,0,0-8.49-3,12.72,12.72,0,0,0-8.4,3,1.57,1.57,0,0,0-.6,1.2v2.7H3A1.59,1.59,0,0,0,4.6,21H19.45A1.58,1.58,0,0,0,21,19.39V16.72A1.61,1.61,0,0,0,20.49,15.52Zm-1,4.32H4.56a.46.46,0,0,1-.43-.48V16.69a.51.51,0,0,1,.16-.37A11.63,11.63,0,0,1,12,13.65v0a11.68,11.68,0,0,1,7.76,2.67.51.51,0,0,1,.16.37v2.65h0A.45.45,0,0,1,19.49,19.84Z',
            prev: 'M9.17,17.45a.66.66,0,0,1-.92,0h0L3.19,12.38l-.08-.09a.64.64,0,0,1,.08-.82h0L8.11,6.55l.08-.07A.65.65,0,0,1,9,6.55H9l.07.09A.65.65,0,0,1,9,7.47H9L5.13,11.36H20.47a.65.65,0,0,1-.12,1.29H5.29l3.88,3.87.07.09A.65.65,0,0,1,9.17,17.45Z',
            radioOn: 'M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2L12,2z M12,17c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5S14.8,17,12,17L12,17z',
            radioOff: 'M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2s10,4.5,10,10S17.5,22,12,22L12,22z M12,3c-5,0-9,4-9,9s4,9,9,9s9-4,9-9c0,0,0,0,0,0C21,7,17,3,12,3z',
            reply: 'M10.05,3.89,3.69,10.32a2.39,2.39,0,0,0,0,3.36l6.36,6.43.07.07a.56.56,0,0,0,.89-.46V14.85h.17a11.26,11.26,0,0,1,8.47,4.71L20,20a.56.56,0,0,0,1-.41l-.35-1.95-.06-.31a10.38,10.38,0,0,0-9.2-8.15l-.37,0V4.28A.56.56,0,0,0,10.05,3.89ZM4.49,11.12,9.88,5.65V9.73a.56.56,0,0,0,.56.56H11a9.24,9.24,0,0,1,8.44,7.27l-.08-.08a12.36,12.36,0,0,0-8.94-3.79h-.1a.55.55,0,0,0-.47.55v4.08L4.49,12.88A1.24,1.24,0,0,1,4.49,11.12Z',
            search: 'M20.23,21a.44.44,0,0,0,.31-.14h0l.35-.32a.51.51,0,0,0,.11-.31.38.38,0,0,0-.11-.28h0l-4.53-4.54a.45.45,0,0,0-.32-.14h-.35a7.2,7.2,0,0,0,1.93-5,7.06,7.06,0,0,0-1-3.67A7.5,7.5,0,0,0,14,4a7.19,7.19,0,0,0-3.68-1A7.15,7.15,0,0,0,6.64,4,7.53,7.53,0,0,0,4,6.64a7.15,7.15,0,0,0-1,3.67A7.19,7.19,0,0,0,4,14a7.5,7.5,0,0,0,2.66,2.65,7.06,7.06,0,0,0,3.67,1,7.2,7.2,0,0,0,5-1.93h0V16a.34.34,0,0,0,.14.28h0L20,20.86A.34.34,0,0,0,20.23,21Zm-9.92-4.5a6.17,6.17,0,0,1-6.19-6.19,6.17,6.17,0,0,1,6.19-6.19,6.17,6.17,0,0,1,6.19,6.19,6.17,6.17,0,0,1-6.19,6.19Z',
            send_outline: 'M20.26,3a.6.6,0,0,1,.69.82h0l-7.2,16.8a.6.6,0,0,1-1.06.07h0L9.16,14.84,3.29,11.31l-.08-.05a.62.62,0,0,1-.21-.5.61.61,0,0,1,.36-.51h0l16.8-7.2ZM18.62,6.23l-8.26,8.26,2.75,4.59Zm-.85-.85L4.91,10.89l4.6,2.75Z',
            sort: 'M6,9.86a.72.72,0,0,0,1,0l4.22-4.33V20.27a.73.73,0,0,0,1.46,0V5.51L17,9.84a.73.73,0,0,0,1,0,.74.74,0,0,0,0-1L12.52,3.22a.72.72,0,0,0-1,0L6,8.83A.73.73,0,0,0,6,9.86Z',
            visibility: 'M3.61,12l-.56-.22a.49.49,0,0,0,0,.44Zm16.78,0,.56.22a.49.49,0,0,0,0-.44ZM12,17.52A7.81,7.81,0,0,1,6,14.7a11.26,11.26,0,0,1-1.41-2A6.66,6.66,0,0,1,4.26,12l-.08-.17v0h0L3.61,12l-.56.22h0v.07l.09.2a5.87,5.87,0,0,0,.38.74,12.7,12.7,0,0,0,1.55,2.23A9.11,9.11,0,0,0,12,18.75ZM3.61,12l.55.22h0v0l.1-.18c.07-.16.18-.39.33-.66A11.56,11.56,0,0,1,6,9.35a7.8,7.8,0,0,1,6-2.87V5.25A9,9,0,0,0,5.11,8.52a12.68,12.68,0,0,0-1.56,2.22c-.17.3-.3.56-.38.74l-.09.21v.06h0ZM12,6.48a7.8,7.8,0,0,1,6,2.87,11.56,11.56,0,0,1,1.41,2c.15.27.26.5.33.66l.08.17v.05h0l.56-.23.55-.22h0V11.7l-.09-.2c-.08-.18-.2-.44-.37-.74a12.31,12.31,0,0,0-1.56-2.22A9,9,0,0,0,12,5.25ZM20.39,12l-.55-.22h0v0l-.1.18c-.07.16-.18.39-.33.66a10.91,10.91,0,0,1-1.41,2,7.81,7.81,0,0,1-6,2.82v1.23a9,9,0,0,0,6.89-3.27,12.68,12.68,0,0,0,1.56-2.22c.17-.3.3-.56.38-.74l.09-.21v-.06h0ZM12,13.84a1.8,1.8,0,0,1-1.8-1.79V12H9a3,3,0,0,0,3,3.07h0ZM13.8,12a1.8,1.8,0,0,1-1.75,1.84H12v1.23A3,3,0,0,0,15,12H13.8ZM12,10.2A1.81,1.81,0,0,1,13.8,12H15a3,3,0,0,0-3-3.07h0ZM12,9a3,3,0,0,0-3,3h1.2A1.81,1.81,0,0,1,12,10.2Z',
            visibility_off: 'M21,12.35v.14l-.12.27-.2.39-.18.35a12.55,12.55,0,0,1-1.55,2.21,10.59,10.59,0,0,1-.74.76l-.85-.9a9.49,9.49,0,0,0,.7-.72,11.48,11.48,0,0,0,1.35-1.95l.09-.16.14-.28.1-.21-.1-.25-.14-.27-.09-.16a11.56,11.56,0,0,0-1.41-2,7.84,7.84,0,0,0-5.65-2.86h-.68A7.52,7.52,0,0,0,9,7.32l-.9-.9a8.55,8.55,0,0,1,3.54-.9h.73a9.07,9.07,0,0,1,6.52,3.26A12.31,12.31,0,0,1,20.45,11l.18.33.14.29L21,12A.69.69,0,0,1,21,12.35Zm-7.19-.28h0l1.06,1.06a2.69,2.69,0,0,0,.14-.75V12a3,3,0,0,0-2.8-2.85h-.38a2.64,2.64,0,0,0-.79.16l1.06,1.06A1.8,1.8,0,0,1,13.79,12.07Zm6.57,7.73a.59.59,0,0,1,0,.8.56.56,0,0,1-.72.06l-.07-.06-3-3A8.34,8.34,0,0,1,12.37,19H12A9,9,0,0,1,5.1,15.7a12.55,12.55,0,0,1-1.55-2.21l-.18-.34-.15-.25L3,12.49a.65.65,0,0,1,0-.34V12l.12-.27.05-.11.15-.29L3.55,11A13.34,13.34,0,0,1,5.1,8.76,12.26,12.26,0,0,1,6.45,7.51L3.16,4.21a.59.59,0,0,1,0-.8.56.56,0,0,1,.74-.06L4,3.41ZM10.19,12.24v.17a1.79,1.79,0,0,0,1.63,1.66h.32a1.64,1.64,0,0,0,.67-.21l-2.44-2.43a1.8,1.8,0,0,0-.18.64Zm5.53,4.49-2-2a2.91,2.91,0,0,1-1.52.56H11.8A3,3,0,0,1,9,12.39V12a3,3,0,0,1,.51-1.49L7.32,8.41A9.28,9.28,0,0,0,6,9.59a11.89,11.89,0,0,0-1.28,1.79l-.21.38L4.36,12l-.1.23.1.21.14.28.09.16a10.72,10.72,0,0,0,1.4,2,8,8,0,0,0,5.66,2.86h.68a7.64,7.64,0,0,0,3.39-1Z'
        }
    },
    theme: {
        disable: true
    }
};
