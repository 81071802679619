import { $axios } from '~/utils/store-accessor';
export var STATES;
(function (STATES) {
    STATES["ERROR"] = "error";
    STATES["LOADING"] = "loading";
    STATES["SUCCESS"] = "success";
})(STATES || (STATES = {}));
export class LazyResource {
    constructor(defaultValue, url, initializer) {
        this.loadingState = {
            promise: null,
            finished: false,
            pending: false
        };
        this.initValue = defaultValue;
        this.value = defaultValue;
        this.initializer = initializer;
        this.url = url;
    }
    reset() {
        this.value = this.initValue;
        this.loadingState = {
            promise: null,
            finished: false,
            pending: false
        };
    }
    async getValue() {
        if (this.isLoaded) {
            return this.value;
        }
        if (this.isPending && this.loadingState.promise) {
            return this.loadingState.promise.then(() => this.value);
        }
        const promise = $axios.get(this.url);
        this.loadingState = {
            pending: true,
            finished: false,
            promise
        };
        await promise.then((response) => {
            this.value = this.initializer(response);
        });
        this.loadingState = {
            pending: false,
            finished: true,
            promise: null
        };
        return this.value;
    }
    get isLoaded() {
        return this.loadingState.finished;
    }
    get isPending() {
        return this.loadingState.pending;
    }
}
export class Page {
    constructor(page) {
        var _a, _b, _c;
        this.external_url = '';
        this.slug = '';
        this.title = '';
        this.external_url = (_a = page.external_url) !== null && _a !== void 0 ? _a : this.external_url;
        this.slug = (_b = page.slug) !== null && _b !== void 0 ? _b : this.slug;
        this.title = (_c = page.title) !== null && _c !== void 0 ? _c : this.title;
    }
    get link() {
        return this.external_url ? this.external_url : `/${this.slug}`;
    }
    isExternal() {
        return !!this.external_url;
    }
}
