var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import Cookies from 'js-cookie';
import { $axios, $notifier, documentsStore, messagesStore, requestsStore } from '~/utils/store-accessor';
import { API_PATHS } from '~/router';
import User from '~/classes/User';
const TOKEN_EXPIRATION_THRESHOLD = 10 * 60; // in seconds
let UserStore = class UserStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.dataFetched = false;
        this.user = null;
        this.token = '';
        this.tokenExpiration = new Date();
    }
    get canAccess() {
        return this.userExists && this.hasVerifiedEmail && !this.isOldSystemUser;
    }
    get hasVerifiedEmail() {
        return !!this.user && this.user.has_verified_email;
    }
    get isOldSystemUser() {
        return !!this.user && this.user.isOldSystemUser;
    }
    get isSSOUser() {
        return !!this.user && this.user.isSSOUser;
    }
    get shouldRefreshToken() {
        return () => {
            const now = new Date();
            now.setSeconds(now.getSeconds() + TOKEN_EXPIRATION_THRESHOLD);
            return this.tokenExpiration < now;
        };
    }
    get userExists() {
        return !!this.user;
    }
    setDataFetched(dataFetched) {
        this.dataFetched = dataFetched;
    }
    setToken({ token, expirationDate }) {
        this.token = token;
        this.tokenExpiration = expirationDate !== null && expirationDate !== void 0 ? expirationDate : new Date();
    }
    setUser(user) {
        this.user = user;
    }
    setUserAccounts(accounts) {
        var _a;
        (_a = this.user) === null || _a === void 0 ? void 0 : _a.setAccounts(accounts);
    }
    setUserData(userData) {
        var _a;
        (_a = this.user) === null || _a === void 0 ? void 0 : _a.setData(userData);
    }
    setUserDocumentsViaPost(documents_via_post) {
        if (this.user) {
            this.user.documents_via_post = documents_via_post;
        }
    }
    setUserSettings(userSettings) {
        var _a;
        (_a = this.user) === null || _a === void 0 ? void 0 : _a.setSettings(userSettings);
    }
    changeNotifications(notifications) {
        return $axios.post(API_PATHS.PROFILE.CHANGE.SETTINGS, {
            notifiable: notifications
        })
            .then((response) => {
            var _a;
            this.setUserSettings(response.data);
            return (_a = this.user) === null || _a === void 0 ? void 0 : _a.settings.notifiable;
        });
    }
    async fetchMe() {
        let user = null;
        await $axios.get(API_PATHS.AUTH.ME)
            .then((response) => {
            user = new User();
            user.setData(response.data);
        });
        this.setUser(user);
    }
    async fetchData() {
        if (!this.userExists) {
            await $notifier.showMessage({
                type: 'error'
            });
            throw new Error('Oops, an error occurred! Code: 1611398463');
        }
        await $axios.get(API_PATHS.USER)
            .then((response) => {
            var _a, _b;
            this.setUserData(response.data);
            this.setDataFetched(true);
            documentsStore.setNumberOfUnreadDocuments((_a = response.data.number_of_unread_documents) !== null && _a !== void 0 ? _a : 0);
            messagesStore.setNumberOfUnreadMessages((_b = response.data.number_of_unread_messages) !== null && _b !== void 0 ? _b : 0);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
        });
    }
    async refreshToken() {
        await $axios.get(API_PATHS.AUTH.REFRESH)
            .then(async (response) => {
            await this.saveToken({
                token: response.data.access_token,
                expiration: response.data.expires_in
            });
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
            this.logout();
        });
    }
    async toggleDocumentsViaPost() {
        if (!this.userExists) {
            await $notifier.showMessage({
                type: 'error'
            });
            throw new Error('Oops, an error occurred! Code: 1611398463');
        }
        await $axios.post(API_PATHS.PROFILE.CHANGE.DOCUMENTS_VIA_POST)
            .then((response) => {
            this.setUserDocumentsViaPost(response.data.documents_via_post);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
        });
    }
    saveToken({ token, expiration }) {
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + expiration);
        this.setToken({ token, expirationDate });
        Cookies.set('token', token, { expires: expirationDate });
        return Promise.resolve();
    }
    deleteToken() {
        this.setToken({ token: '' });
        Cookies.remove('token');
        return Promise.resolve();
    }
    logout() {
        this.setUser(null);
        this.deleteToken();
        this.setDataFetched(false);
        documentsStore.reset();
        messagesStore.reset();
        requestsStore.reset();
        return Promise.resolve();
    }
};
__decorate([
    Mutation
], UserStore.prototype, "setDataFetched", null);
__decorate([
    Mutation
], UserStore.prototype, "setToken", null);
__decorate([
    Mutation
], UserStore.prototype, "setUser", null);
__decorate([
    Mutation
], UserStore.prototype, "setUserAccounts", null);
__decorate([
    Mutation
], UserStore.prototype, "setUserData", null);
__decorate([
    Mutation
], UserStore.prototype, "setUserDocumentsViaPost", null);
__decorate([
    Mutation
], UserStore.prototype, "setUserSettings", null);
__decorate([
    Action
], UserStore.prototype, "changeNotifications", null);
__decorate([
    Action
], UserStore.prototype, "fetchMe", null);
__decorate([
    Action
], UserStore.prototype, "fetchData", null);
__decorate([
    Action
], UserStore.prototype, "refreshToken", null);
__decorate([
    Action
], UserStore.prototype, "toggleDocumentsViaPost", null);
__decorate([
    Action
], UserStore.prototype, "saveToken", null);
__decorate([
    Action
], UserStore.prototype, "deleteToken", null);
__decorate([
    Action
], UserStore.prototype, "logout", null);
UserStore = __decorate([
    Module({
        name: 'user',
        stateFactory: true,
        namespaced: true
    })
], UserStore);
export default UserStore;
