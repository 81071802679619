var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import download from 'downloadjs';
import { Action, Module, Mutation, VuexModule, config } from 'vuex-module-decorators';
import { LazyResource } from '~/classes/App';
import Document, { DocumentArea, DocumentAreaType } from '~/classes/Document';
import { $axios, $notifier } from '~/utils/store-accessor';
import { API_PATHS } from '~/router';
config.rawError = true;
/*
 * Factory for lazy loading documents for a documentArea
 */
const documentsResourceFactory = (area) => {
    const defaultValue = [];
    return new LazyResource(defaultValue, `${API_PATHS.DOCUMENTS.AREA_DOCUMENTS}/${area.id}`, (response) => {
        if (response.data && response.data.length) {
            return response.data.map((document) => new Document(document));
        }
        return defaultValue;
    });
};
/*
 * Singleton resource for fetching document areas
 */
const areasResource = new LazyResource([], API_PATHS.DOCUMENTS.AREAS, (response) => {
    const areas = [];
    response.data.forEach((area) => {
        const documentArea = new DocumentArea(area);
        areas.push({
            area: documentArea,
            documents: documentsResourceFactory(documentArea)
        });
    });
    return areas;
});
/*
 * Documents store
 */
let DocumentsStore = class DocumentsStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.areas = [];
        this.numberOfUnreadDocuments = 0;
    }
    setAreas(areas) {
        this.areas = areas;
    }
    setDocumentsToArea({ area, documents }) {
        area.documents = documents;
    }
    setDocumentIsread(document) {
        if (document.is_unread) {
            let isInTabArea = false;
            this.areas.forEach((area) => {
                area.documents.forEach((areaDocument) => {
                    if (document.id === areaDocument.id) {
                        areaDocument.is_unread = false;
                        if (area.area === DocumentAreaType.TAB) {
                            isInTabArea = true;
                        }
                    }
                });
            });
            if (isInTabArea) {
                this.numberOfUnreadDocuments = Math.max(this.numberOfUnreadDocuments - 1, 0);
            }
        }
    }
    setNumberOfUnreadDocuments(count) {
        this.numberOfUnreadDocuments = Math.max(count, 0);
    }
    downloadDocument(document) {
        return $axios.get(document.download_url, {
            responseType: 'blob'
        }).then((response) => {
            const contentType = response.headers['content-type'];
            download(response.data, document.download_filename, contentType);
            this.setDocumentIsread(document);
        }).catch((e) => {
            $notifier.showMessage({
                content: this.store.$i18n.t('ERROR_DOCUMENT_DOWNLOAD'),
                type: 'error'
            });
            throw e;
        });
    }
    async fetchAreas() {
        if (!areasResource.isLoaded) {
            const areaResources = await areasResource.getValue();
            this.setAreas(areaResources.map(resource => resource.area));
        }
    }
    async fetchDocumentsForArea(area) {
        if (!area || !this.areas.some(item => item.id === area.id)) {
            return Promise.reject(new Error('Document Area not found'));
        }
        const areaResources = await areasResource.getValue();
        const areaResource = areaResources.find(item => item.area.id === area.id);
        if (!areaResource) {
            return Promise.reject(new Error('Document Area not found'));
        }
        this.setDocumentsToArea({
            area,
            documents: await areaResource.documents.getValue()
        });
    }
    async getAreaBySlug(areaSlug) {
        return await this.getDocumentAreas().then((areas) => {
            return areas.find(area => area.slug === areaSlug);
        });
    }
    async getAreaByType(areaType) {
        return await this.getDocumentAreas().then((areas) => {
            return areas.find(area => area.area === areaType);
        });
    }
    async getDocumentAreas() {
        await this.fetchAreas();
        return this.areas;
    }
    async getDocumentsForArea(area) {
        if (!area) {
            return Promise.reject(new Error('Document Area not found'));
        }
        await this.fetchDocumentsForArea(area);
        return area.documents;
    }
    reset() {
        this.setAreas([]);
        this.setNumberOfUnreadDocuments(0);
        areasResource.reset();
        return Promise.resolve();
    }
};
__decorate([
    Mutation
], DocumentsStore.prototype, "setAreas", null);
__decorate([
    Mutation
], DocumentsStore.prototype, "setDocumentsToArea", null);
__decorate([
    Mutation
], DocumentsStore.prototype, "setDocumentIsread", null);
__decorate([
    Mutation
], DocumentsStore.prototype, "setNumberOfUnreadDocuments", null);
__decorate([
    Action
], DocumentsStore.prototype, "downloadDocument", null);
__decorate([
    Action
], DocumentsStore.prototype, "fetchAreas", null);
__decorate([
    Action
], DocumentsStore.prototype, "fetchDocumentsForArea", null);
__decorate([
    Action
], DocumentsStore.prototype, "getAreaBySlug", null);
__decorate([
    Action
], DocumentsStore.prototype, "getAreaByType", null);
__decorate([
    Action
], DocumentsStore.prototype, "getDocumentAreas", null);
__decorate([
    Action
], DocumentsStore.prototype, "getDocumentsForArea", null);
__decorate([
    Action
], DocumentsStore.prototype, "reset", null);
DocumentsStore = __decorate([
    Module({
        name: 'documents',
        stateFactory: true,
        namespaced: true
    })
], DocumentsStore);
export default DocumentsStore;
