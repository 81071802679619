import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_82fe5e0c from 'nuxt_plugin_sentryserver_82fe5e0c' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_58c75f72 from 'nuxt_plugin_sentryclient_58c75f72' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_vuescrollto_d19c13da from 'nuxt_plugin_vuescrollto_d19c13da' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_plugin_4cd942bb from 'nuxt_plugin_plugin_4cd942bb' // Source: ./matomo/plugin.js (mode: 'client')
import nuxt_plugin_pluginutils_1ecf4c76 from 'nuxt_plugin_pluginutils_1ecf4c76' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_3c2cec60 from 'nuxt_plugin_pluginrouting_3c2cec60' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_24df98c2 from 'nuxt_plugin_pluginmain_24df98c2' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_workbox_05fa26d2 from 'nuxt_plugin_workbox_05fa26d2' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_141f0836 from 'nuxt_plugin_metaplugin_141f0836' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_dayjsplugin_034bc8e7 from 'nuxt_plugin_dayjsplugin_034bc8e7' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_axios_7736a736 from 'nuxt_plugin_axios_7736a736' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_matomo_ad92b434 from 'nuxt_plugin_matomo_ad92b434' // Source: ../plugins/matomo.ts (mode: 'all')
import nuxt_plugin_notifier_3e431691 from 'nuxt_plugin_notifier_3e431691' // Source: ../plugins/notifier.ts (mode: 'all')
import nuxt_plugin_axios_5659cf26 from 'nuxt_plugin_axios_5659cf26' // Source: ../plugins/axios.ts (mode: 'all')
import nuxt_plugin_components_50cb0ca1 from 'nuxt_plugin_components_50cb0ca1' // Source: ../plugins/components.ts (mode: 'all')
import nuxt_plugin_sentry_e4786790 from 'nuxt_plugin_sentry_e4786790' // Source: ../plugins/sentry.ts (mode: 'all')
import nuxt_plugin_veevalidate_1a0c172c from 'nuxt_plugin_veevalidate_1a0c172c' // Source: ../plugins/vee-validate.ts (mode: 'all')
import nuxt_plugin_vuei18n_5a00ac5c from 'nuxt_plugin_vuei18n_5a00ac5c' // Source: ../plugins/vue-i18n.ts (mode: 'all')
import nuxt_plugin_vuetify_d6afc056 from 'nuxt_plugin_vuetify_d6afc056' // Source: ../plugins/vuetify.ts (mode: 'all')
import nuxt_plugin_nuxtclientinit_3893ccef from 'nuxt_plugin_nuxtclientinit_3893ccef' // Source: ../plugins/nuxt-client-init.ts (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Kundenportal","htmlAttrs":{"lang":"de"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"csp","http-equiv":"Content-Security-Policy","content":"default-src 'self' *.concisa.at; img-src https:\u002F\u002F* data:; style-src 'self' 'unsafe-inline' data: *.concisa.at cdn.jsdelivr.net; script-src 'self' 'unsafe-inline' 'unsafe-eval' *.concisa.at https:\u002F\u002Fcdn.jsdelivr.net; connect-src 'self' *.concisa.at cdn.jsdelivr.net; worker-src 'self' *.concisa.at blob: data:;"}],"link":[],"style":[],"script":[{"src":"https:\u002F\u002Fmatomo.concisa.at\u002Fpiwik.js","body":true,"defer":true,"async":true}]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_82fe5e0c === 'function') {
    await nuxt_plugin_sentryserver_82fe5e0c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_58c75f72 === 'function') {
    await nuxt_plugin_sentryclient_58c75f72(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_d19c13da === 'function') {
    await nuxt_plugin_vuescrollto_d19c13da(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_plugin_4cd942bb === 'function') {
    await nuxt_plugin_plugin_4cd942bb(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_1ecf4c76 === 'function') {
    await nuxt_plugin_pluginutils_1ecf4c76(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_3c2cec60 === 'function') {
    await nuxt_plugin_pluginrouting_3c2cec60(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_24df98c2 === 'function') {
    await nuxt_plugin_pluginmain_24df98c2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_05fa26d2 === 'function') {
    await nuxt_plugin_workbox_05fa26d2(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_141f0836 === 'function') {
    await nuxt_plugin_metaplugin_141f0836(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_034bc8e7 === 'function') {
    await nuxt_plugin_dayjsplugin_034bc8e7(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_7736a736 === 'function') {
    await nuxt_plugin_axios_7736a736(app.context, inject)
  }

  if (typeof nuxt_plugin_matomo_ad92b434 === 'function') {
    await nuxt_plugin_matomo_ad92b434(app.context, inject)
  }

  if (typeof nuxt_plugin_notifier_3e431691 === 'function') {
    await nuxt_plugin_notifier_3e431691(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659cf26 === 'function') {
    await nuxt_plugin_axios_5659cf26(app.context, inject)
  }

  if (typeof nuxt_plugin_components_50cb0ca1 === 'function') {
    await nuxt_plugin_components_50cb0ca1(app.context, inject)
  }

  if (typeof nuxt_plugin_sentry_e4786790 === 'function') {
    await nuxt_plugin_sentry_e4786790(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_1a0c172c === 'function') {
    await nuxt_plugin_veevalidate_1a0c172c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuei18n_5a00ac5c === 'function') {
    await nuxt_plugin_vuei18n_5a00ac5c(app.context, inject)
  }

  if (typeof nuxt_plugin_vuetify_d6afc056 === 'function') {
    await nuxt_plugin_vuetify_d6afc056(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtclientinit_3893ccef === 'function') {
    await nuxt_plugin_nuxtclientinit_3893ccef(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
