import Request from '~/classes/Request';
import { i18n, userStore } from '~/utils/store-accessor';
export default class RAKRegistrationReductionRequest extends Request {
    constructor(data) {
        var _a, _b;
        super(data);
        this.introtext = '<p>Sie erklären, eine Ermäßigung des Beitrags auf <strong>mindestens 20 %</strong> für 12 oder 24 Kalendermonate ab Ersteintragung in Anspruch zu nehmen. Wenn Sie als Ermäßigungszeitraum 12 Kalendermonate ab Ersteintragung wählen, haben Sie die Möglichkeit, vor Ablauf der ersten 12 Monate, eine weitere Ermäßigung für das 13. – 24. Monat in Anspruch zu nehmen.</p>';
        this.title = 'Erklärung zur Ermäßigung des Beitrags wegen Ersteintragung';
        this.teaser = {
            introtext: 'Eine Ermäßigung des Beitrags auf mindestens 20 % kann für 12 oder 24 Monate ab Ersteintragung in Anspruch genommen werden.',
            title: 'Ermäßigung des Beitrags wegen Ersteintragung'
        };
        this.type = 'registration-reduction';
        this.detailComponent = () => import('~/portals/rak/components/requests/registration-reduction.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/registration-reduction.vue');
        const availableAccounts = ((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.availableAccounts) || [];
        if (availableAccounts.length) {
            this.introtext += `<p>Der <strong>Beitrag</strong> zur Versorgungseinrichtung Teil B beträgt für das Jahr ${availableAccounts[0].jahr_ordentlicher_jahresbeitrag} <strong>${i18n.n(availableAccounts[0].ordentlicher_jahresbeitrag, 'currency')}</strong> p.a..</p>`;
        }
        this.data = (_b = data.data) !== null && _b !== void 0 ? _b : {};
    }
}
