var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import isIos from 'is-ios';
import Cookies from 'js-cookie';
import { Component, Vue } from 'nuxt-property-decorator';
const DAYS_IN_YEAR = 365;
let BookmarkHint = class BookmarkHint extends Vue {
    constructor() {
        super(...arguments);
        this.closed = true;
    }
    close() {
        Cookies.set('bookmarkHintClosed', '1', { expires: DAYS_IN_YEAR });
        this.closed = true;
    }
    get isIos() {
        return isIos;
    }
    get isChrome() {
        if (typeof navigator === 'undefined') {
            return false;
        }
        return /Chrome\W\d/.test(navigator.userAgent) && /Android/.test(navigator.userAgent);
    }
    /*
     * Lifecycle HOOKS
     */
    mounted() {
        const isBookmarkHintClosed = Cookies.get('bookmarkHintClosed') === '1';
        this.closed = isBookmarkHintClosed || !(this.isIos || this.isChrome);
    }
};
BookmarkHint = __decorate([
    Component
], BookmarkHint);
export default BookmarkHint;
