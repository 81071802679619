import { PATHS } from '~/router';
import { userStore } from '~/store';
const guestMiddleware = ({ redirect, route }) => {
    if (!userStore.userExists) {
        return redirect(PATHS.AUTH.LOGIN);
    }
    else if (userStore.isOldSystemUser) {
        if (route.path !== PATHS.AUTH.REGISTER.UPDATE && route.path !== PATHS.AUTH.LOGOUT) {
            return redirect(PATHS.AUTH.REGISTER.UPDATE);
        }
    }
    else if (userStore.isSSOUser && !userStore.hasVerifiedEmail) {
        if (route.path !== PATHS.AUTH.SSO.VERIFY && route.path !== PATHS.AUTH.LOGOUT) {
            return redirect(PATHS.AUTH.SSO.VERIFY);
        }
    }
    else if (!userStore.hasVerifiedEmail) {
        return redirect(PATHS.AUTH.LOGIN);
    }
};
export default guestMiddleware;
