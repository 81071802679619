var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { Page } from '~/classes/App';
let PageLink = class PageLink extends Vue {
};
__decorate([
    Prop(Page)
], PageLink.prototype, "page", void 0);
__decorate([
    Prop({ default: '', type: String })
], PageLink.prototype, "target", void 0);
PageLink = __decorate([
    Component({
        render(h) {
            const data = {};
            let tag = 'span';
            if (this.page && this.page.link) {
                if (this.page.isExternal() || this.target === '_blank') {
                    data.attrs = {
                        href: this.page.link,
                        target: '_blank'
                    };
                    tag = 'a';
                }
                else {
                    data.props = {
                        to: this.page.link
                    };
                    tag = 'nuxt-link';
                }
                data.on = {
                    ...this.$listeners
                };
            }
            return h(tag, data, this.$slots.default || this.page.title);
        }
    })
], PageLink);
export default PageLink;
