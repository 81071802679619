import { $axios } from '~/utils/store-accessor';
import { userStore } from '~/store';
export default async ({ $sentry }) => {
    const token = userStore.token;
    if (process.server) {
        if (token) {
            $axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        }
        else {
            delete $axios.defaults.headers.common.Authorization;
        }
    }
    if (token) {
        try {
            if (!userStore.userExists) {
                await userStore.fetchMe();
            }
            if (userStore.canAccess && !userStore.dataFetched) {
                await userStore.fetchData();
            }
            if (userStore.shouldRefreshToken()) {
                await userStore.refreshToken();
            }
        }
        catch (error) {
            $sentry.captureException(error);
            await userStore.logout();
        }
    }
};
