import Request from '~/classes/Request';
import { userStore, i18n } from '~/utils/store-accessor';
export default class RAKExemptionRequest extends Request {
    constructor(data) {
        var _a;
        super(data);
        this.introtext = '<p>Versicherte, die verpflichtend einer gesetzlich geregelten Versorgungseinrichtung im In- oder Ausland angehören, können auf Antrag für <strong>jeweils ein Kalenderjahr</strong> von der Beitragspflicht befreit werden. Der Antrag ist bis spätestens 31.01. des Jahres einzureichen.</p><p>Bei Bedarf muss im nächsten Jahr ein neuerlicher Befreiungsantrag gestellt werden. Im Falle der Ersteintragung ist dieser Antrag innerhalb von zwei Monaten ab dem Tag der Eintragung einzureichen.</p>';
        this.title = 'Antrag auf Befreiung wegen gesetzlich geregelter verpflichtender Altersvorsorge';
        this.teaser = {
            introtext: 'Sollten Sie bereits einer anderen gesetzlich geregelten Altersvorsorge verpflichtend angehören, können Sie sich jährlich vom Beitrag befreien lassen.',
            title: 'Beitragsbefreiung wegen anderer gesetzlich geregelter verpflichtender Altersvorsorge'
        };
        this.type = 'exemption';
        this.detailComponent = () => import('~/portals/rak/components/requests/exemption.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/exemption.vue');
        const availableAccounts = ((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.availableAccounts) || [];
        if (availableAccounts.length) {
            this.introtext += `<p>Der <strong>Beitrag</strong> zur Versorgungseinrichtung Teil B beträgt für das Jahr ${availableAccounts[0].jahr_ordentlicher_jahresbeitrag} <strong>${i18n.n(availableAccounts[0].ordentlicher_jahresbeitrag, 'currency')}</strong> p.a..</p>`;
        }
    }
}
