import Request from '~/classes/Request';
import VKDisposalRequest from '~/portals/vk/requests/Disposal';
import RAKDeathCompensationRequest from '~/portals/rak/requests/DeathCompensation';
import RAKExemptionRequest from '~/portals/rak/requests/Exemption';
import RAKIncomeReductionRequest from '~/portals/rak/requests/IncomeReduction';
import RAKRegistrationReductionRequest from '~/portals/rak/requests/RegistrationReduction';
import RAKRetirementContributionExemptionRequest from '~/portals/rak/requests/RetirementContributionExemption';
import RAKRetirementWithdrawRequest from '~/portals/rak/requests/RetirementWithdraw';
import RAKSecondYearRegistrationReductionRequest from '~/portals/rak/requests/SecondYearRegistrationReduction';
import RAKSepaRequest from '~/portals/rak/requests/Sepa';
import RAKVrgChangeRequest from '~/portals/rak/requests/VrgChange';
export function requestFactoryByType(type, data = {}) {
    switch (type) {
        case 'disposal':
            return new VKDisposalRequest(data);
        case 'death_compensation':
            return new RAKDeathCompensationRequest(data);
        case 'exemption':
            return new RAKExemptionRequest(data);
        case 'income_reduction':
            return new RAKIncomeReductionRequest(data);
        case 'registration_reduction':
            return new RAKRegistrationReductionRequest(data);
        case 'retirement_contribution_exemption':
            return new RAKRetirementContributionExemptionRequest(data);
        case 'retirement_withdraw':
            return new RAKRetirementWithdrawRequest(data);
        case 'second_year_registration_reduction':
            return new RAKSecondYearRegistrationReductionRequest(data);
        case 'sepa':
            return new RAKSepaRequest(data);
        case 'vrg_change':
            return new RAKVrgChangeRequest(data);
        default:
            return new Request(data);
    }
}
