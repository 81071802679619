import { PATHS } from '~/router';
import { documentsStore, messagesStore, userStore } from '~/utils/store-accessor';
export const SIDEBAR_NAV_ITEMS = [
    { text: 'Kontostand', icon: '$vuetify.icons.account_balance', path: PATHS.ACCOUNT.INDEX },
    { text: 'Dokumente', icon: '$vuetify.icons.file_document_outline', path: PATHS.DOCUMENTS.INDEX },
    { text: 'Anträge', icon: '$vuetify.icons.edit', path: PATHS.REQUESTS.INDEX },
    { text: 'Postfach', icon: '$vuetify.icons.email_outline', path: PATHS.MESSAGES.INDEX }
];
export default class Portal {
    constructor(data) {
        var _a, _b, _c, _d;
        this.key = '';
        this.matomoSiteId = 0;
        this.showBookmarkHint = false;
        this.sidebarNavItems = SIDEBAR_NAV_ITEMS;
        this.userIdentifier = 'ssn';
        this.canResetUsername = true;
        this.key = (_a = data.key) !== null && _a !== void 0 ? _a : this.key;
        this.matomoSiteId = (_b = data.matomoSiteId) !== null && _b !== void 0 ? _b : this.matomoSiteId;
        this.showBookmarkHint = (_c = data.showBookmarkHint) !== null && _c !== void 0 ? _c : this.showBookmarkHint;
        this.userIdentifier = (_d = data.userIdentifier) !== null && _d !== void 0 ? _d : this.userIdentifier;
    }
    get sidebarNavigation() {
        if (!this.sidebarNavItems) {
            return [];
        }
        for (const item of this.sidebarNavItems) {
            if (item.path === PATHS.ACCOUNT.INDEX) {
                item.disabled = !this.canAccessAccount();
            }
            else if (item.path === PATHS.DOCUMENTS.INDEX) {
                item.chip = documentsStore.numberOfUnreadDocuments;
            }
            else if (item.path === PATHS.REQUESTS.INDEX) {
                item.disabled = !this.canAccessRequests();
            }
            else if (item.path === PATHS.MESSAGES.INDEX) {
                item.chip = messagesStore.numberOfUnreadMessages;
            }
        }
        return this.sidebarNavItems;
    }
    canAccessAccount() {
        var _a;
        return !!((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.hasAccount());
    }
    canAccessRequests() {
        var _a;
        return !!((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.hasAccount());
    }
    hasRequests() {
        return !!this.sidebarNavigation.find(item => item.path === PATHS.REQUESTS.INDEX);
    }
}
