var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, PropSync, Ref } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
import { API_PATHS, PATHS } from '~/router';
import MetaNav from '~/components/nav/MetaNav.vue';
import { getHostByKey } from '~/store/app';
import { $notifier, userStore } from '~/utils/store-accessor';
const app = namespace('app');
const user = namespace('user');
let Sidebar = class Sidebar extends Vue {
    constructor() {
        super(...arguments);
        this.PATHS = PATHS;
    }
    get sidebarImage() {
        switch (this.portal.key) {
            case 'bpk':
                return 'bpk-sidebar.png';
            case 'pk':
                return 'pk-sidebar.svg';
            case 'vk':
            case 'rak':
            default:
                return 'default.svg';
        }
    }
    get logo() {
        if (this.portal.key === 'rak') {
            let src = '/images/logos/rak/logo-external-white.svg?v=20240228';
            if (this.isUserLoggedIn) {
                switch (this.user.chamberNumber) {
                    case 1:
                        src = '/images/logos/rak/chambers/wien.svg';
                        break;
                    case 2:
                        src = '/images/logos/rak/chambers/noe.svg';
                        break;
                    case 3:
                        src = '/images/logos/rak/chambers/burgenland.svg';
                        break;
                    case 4:
                        src = '/images/logos/rak/chambers/oberoesterreich.svg';
                        break;
                    case 5:
                        src = '/images/logos/rak/chambers/salzburg.svg';
                        break;
                    case 6:
                        src = '/images/logos/rak/chambers/steiermark.svg';
                        break;
                    case 7:
                        src = '/images/logos/rak/chambers/kaernten.svg';
                        break;
                    case 8:
                        src = '/images/logos/rak/chambers/tirol.svg';
                        break;
                    case 9:
                        src = '/images/logos/rak/chambers/vorarlberg.svg';
                        break;
                    default:
                        src = '/images/logos/rak/logo-external.svg?v=20240228';
                }
            }
            return {
                alt: 'Die Österreichischen Rechtsanwälte',
                height: this.$vuetify.breakpoint.mdAndDown ? 48 : 64,
                src
            };
        }
        else if (this.portal.key === 'bpk') {
            return {
                alt: this.$t('logo'),
                height: this.$vuetify.breakpoint.mdAndDown ? 48 : 60,
                src: '/images/logos/bpk/logo.svg'
            };
        }
        return {
            alt: this.$t('logo'),
            height: this.$vuetify.breakpoint.mdAndDown ? 58 : 74,
            src: `/images/logos/${this.portal.key}/logo${this.isUserLoggedIn ? '' : '-white'}.svg`
        };
    }
    get sidebarWidth() {
        if (this.isUserLoggedIn) {
            if (this.$vuetify.breakpoint.md) {
                return 276;
            }
            else if (this.$vuetify.breakpoint.smAndDown) {
                return 375;
            }
            else {
                return 356;
            }
        }
        return '27.5%';
    }
    getDomainUrl(key) {
        const host = getHostByKey(key);
        if (host) {
            return `https://${host}`;
        }
        return `https://${getHostByKey(this.portal.key)}`;
    }
    mounted() {
        window.addEventListener('resize', () => {
            this.$nextTick(() => {
                var _a;
                (_a = this.drawer) === null || _a === void 0 ? void 0 : _a.callUpdate();
            });
        });
    }
    switchPortal(e, tenant) {
        e.preventDefault();
        this.$axios.get(`${API_PATHS.AUTH.T2T_PREPARE}/${tenant}`)
            .then((response) => {
            userStore.logout().then(() => {
                window.location.href = `${this.getDomainUrl(tenant)}${response.data.url}`;
            });
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
        });
    }
};
__decorate([
    Ref()
], Sidebar.prototype, "drawer", void 0);
__decorate([
    PropSync('visibility', { type: Boolean, default: null })
], Sidebar.prototype, "isVisible", void 0);
__decorate([
    app.Getter
], Sidebar.prototype, "portal", void 0);
__decorate([
    app.Getter
], Sidebar.prototype, "sidebarNavigation", void 0);
__decorate([
    user.Getter('canAccess')
], Sidebar.prototype, "isUserLoggedIn", void 0);
__decorate([
    user.State
], Sidebar.prototype, "user", void 0);
Sidebar = __decorate([
    Component({
        components: {
            MetaNav
        }
    })
], Sidebar);
export default Sidebar;
