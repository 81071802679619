import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d552c06 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _209e3164 = () => interopDefault(import('../pages/documents.vue' /* webpackChunkName: "pages/documents" */))
const _31b0292e = () => interopDefault(import('../pages/documents/_area/index.vue' /* webpackChunkName: "pages/documents/_area/index" */))
const _6ea67826 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _33e5ca29 = () => interopDefault(import('../pages/messages/index.vue' /* webpackChunkName: "pages/messages/index" */))
const _7087c5b5 = () => interopDefault(import('../pages/messages/create.vue' /* webpackChunkName: "pages/messages/create" */))
const _49ac6c46 = () => interopDefault(import('../pages/messages/inbox.vue' /* webpackChunkName: "pages/messages/inbox" */))
const _18e24ade = () => interopDefault(import('../pages/messages/sent.vue' /* webpackChunkName: "pages/messages/sent" */))
const _3182b582 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _7d640082 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _b60adf34 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _08fcda9e = () => interopDefault(import('../pages/requests.vue' /* webpackChunkName: "pages/requests" */))
const _245672a1 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _9139351c = () => interopDefault(import('../pages/requests/new/index.vue' /* webpackChunkName: "pages/requests/new/index" */))
const _eab6fa6c = () => interopDefault(import('../pages/requests/submitted.vue' /* webpackChunkName: "pages/requests/submitted" */))
const _76caed8e = () => interopDefault(import('../pages/requests/new/_type.vue' /* webpackChunkName: "pages/requests/new/_type" */))
const _63121e6c = () => interopDefault(import('../pages/auth/impersonate.vue' /* webpackChunkName: "pages/auth/impersonate" */))
const _79ae379c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _0929c467 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _f237e34e = () => interopDefault(import('../pages/auth/registration/index.vue' /* webpackChunkName: "pages/auth/registration/index" */))
const _04b3be69 = () => interopDefault(import('../pages/auth/t2t.vue' /* webpackChunkName: "pages/auth/t2t" */))
const _1a11f0d2 = () => interopDefault(import('../pages/auth/registration/update.vue' /* webpackChunkName: "pages/auth/registration/update" */))
const _23ba2efc = () => interopDefault(import('../pages/auth/registration/verify.vue' /* webpackChunkName: "pages/auth/registration/verify" */))
const _65e6258a = () => interopDefault(import('../pages/auth/reset/password/index.vue' /* webpackChunkName: "pages/auth/reset/password/index" */))
const _655b9730 = () => interopDefault(import('../pages/auth/reset/user.vue' /* webpackChunkName: "pages/auth/reset/user" */))
const _4aaa0973 = () => interopDefault(import('../pages/auth/reset/username.vue' /* webpackChunkName: "pages/auth/reset/username" */))
const _011fa3c8 = () => interopDefault(import('../pages/auth/sso/login.vue' /* webpackChunkName: "pages/auth/sso/login" */))
const _1bce0156 = () => interopDefault(import('../pages/auth/sso/verify.vue' /* webpackChunkName: "pages/auth/sso/verify" */))
const _399cbc47 = () => interopDefault(import('../pages/profile/settings/delete.vue' /* webpackChunkName: "pages/profile/settings/delete" */))
const _537f3e30 = () => interopDefault(import('../pages/profile/settings/email.vue' /* webpackChunkName: "pages/profile/settings/email" */))
const _5924af92 = () => interopDefault(import('../pages/profile/settings/password.vue' /* webpackChunkName: "pages/profile/settings/password" */))
const _6d15e5e0 = () => interopDefault(import('../pages/auth/reset/password/verify.vue' /* webpackChunkName: "pages/auth/reset/password/verify" */))
const _69bccf68 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4d7fd3d0 = () => interopDefault(import('../pages/documents/detail/_id/index.vue' /* webpackChunkName: "pages/documents/detail/_id/index" */))
const _3d19e410 = () => interopDefault(import('../pages/messages/detail/_id/index.vue' /* webpackChunkName: "pages/messages/detail/_id/index" */))
const _7aceea80 = () => interopDefault(import('../pages/requests/detail/_id/index.vue' /* webpackChunkName: "pages/requests/detail/_id/index" */))
const _57ab4775 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3d552c06,
    name: "account___de"
  }, {
    path: "/documents",
    component: _209e3164,
    name: "documents___de",
    children: [{
      path: ":area",
      component: _31b0292e,
      name: "documents-area___de"
    }]
  }, {
    path: "/messages",
    component: _6ea67826,
    children: [{
      path: "",
      component: _33e5ca29,
      name: "messages___de"
    }, {
      path: "create",
      component: _7087c5b5,
      name: "messages-create___de"
    }, {
      path: "inbox",
      component: _49ac6c46,
      name: "messages-inbox___de"
    }, {
      path: "sent",
      component: _18e24ade,
      name: "messages-sent___de"
    }]
  }, {
    path: "/profile",
    component: _3182b582,
    children: [{
      path: "",
      component: _7d640082,
      name: "profile___de"
    }, {
      path: "settings",
      component: _b60adf34,
      name: "profile-settings___de"
    }]
  }, {
    path: "/requests",
    component: _08fcda9e,
    children: [{
      path: "",
      component: _245672a1,
      name: "requests___de"
    }, {
      path: "new",
      component: _9139351c,
      name: "requests-new___de"
    }, {
      path: "submitted",
      component: _eab6fa6c,
      name: "requests-submitted___de"
    }, {
      path: "new/:type",
      component: _76caed8e,
      name: "requests-new-type___de"
    }]
  }, {
    path: "/auth/impersonate",
    component: _63121e6c,
    name: "auth-impersonate___de"
  }, {
    path: "/auth/login",
    component: _79ae379c,
    name: "auth-login___de"
  }, {
    path: "/auth/logout",
    component: _0929c467,
    name: "auth-logout___de"
  }, {
    path: "/auth/registration",
    component: _f237e34e,
    name: "auth-registration___de"
  }, {
    path: "/auth/t2t",
    component: _04b3be69,
    name: "auth-t2t___de"
  }, {
    path: "/auth/registration/update",
    component: _1a11f0d2,
    name: "auth-registration-update___de"
  }, {
    path: "/auth/registration/verify",
    component: _23ba2efc,
    name: "auth-registration-verify___de"
  }, {
    path: "/auth/reset/password",
    component: _65e6258a,
    name: "auth-reset-password___de"
  }, {
    path: "/auth/reset/user",
    component: _655b9730,
    name: "auth-reset-user___de"
  }, {
    path: "/auth/reset/username",
    component: _4aaa0973,
    name: "auth-reset-username___de"
  }, {
    path: "/auth/sso/login",
    component: _011fa3c8,
    name: "auth-sso-login___de"
  }, {
    path: "/auth/sso/verify",
    component: _1bce0156,
    name: "auth-sso-verify___de"
  }, {
    path: "/profile/settings/delete",
    component: _399cbc47,
    name: "profile-settings-delete___de"
  }, {
    path: "/profile/settings/email",
    component: _537f3e30,
    name: "profile-settings-email___de"
  }, {
    path: "/profile/settings/password",
    component: _5924af92,
    name: "profile-settings-password___de"
  }, {
    path: "/auth/reset/password/verify",
    component: _6d15e5e0,
    name: "auth-reset-password-verify___de"
  }, {
    path: "/",
    component: _69bccf68,
    name: "index___de"
  }, {
    path: "/documents/detail/:id",
    component: _4d7fd3d0,
    name: "documents-detail-id___de"
  }, {
    path: "/messages/detail/:id",
    component: _3d19e410,
    name: "messages-detail-id___de"
  }, {
    path: "/requests/detail/:id",
    component: _7aceea80,
    name: "requests-detail-id___de"
  }, {
    path: "/requests/new/:type?",
    component: _76caed8e,
    name: "requests-new-type___de"
  }, {
    path: "/*",
    component: _57ab4775,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
