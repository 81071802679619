// Component to render content elements loop
import { Vue } from 'nuxt-property-decorator';
export default Vue.extend({
    name: 'CeRenderer',
    functional: true,
    props: {
        /**
         * Array of content elements
         */
        content: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    render(createElement, ctx) {
        // render standalone component
        const renderComponent = (element) => {
            return createElement('ce-dynamic', {
                props: {
                    data: element,
                    component: element.component
                }
            });
        };
        // render loop of content elements
        return ctx.props.content.map(element => renderComponent(element));
    }
});
