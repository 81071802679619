import Request from '~/classes/Request';
export default class RAKVrgChangeRequest extends Request {
    constructor(data) {
        var _a;
        super(data);
        this.introtext = '<p>Der Wechsel der VRG kann bis 30.11. des aktuellen mit Wirksamkeit ab 01.01. des Folgejahres erfolgen, anderenfalls findet der Wechsel zum 01.01. des darauffolgenden Jahres statt.</p><p>Bei Ersteintragung kann innerhalb von 2 Monaten eine VRG ausgewählt werden. Die Erklärung wirkt auf den Tag der Eintragung zurück. Wird von dem Wahlrecht kein Gebrauch gemacht, erfolgt die Veranlagung in der VRG ALPS 30.</p><p><u>Bitte beachten Sie:</u><br>Bei einem Wechsel von einer VRG in eine andere muss eine etwaige Gewinnreserve berücksichtigt werden. Bei einem Wechsel von einer VRG ohne Gewinnreserve in eine VRG mit einer solchen wird ein Teil des Kapitals in die Gewinnreserve umgebucht. Bei einem Wechsel von einer VRG mit einer höheren Gewinnreserve in eine solche mit einer geringeren oder keiner Gewinnreserve erhöht sich demnach der Kontostand. Die Höhe des Gesamtkapitals wird nicht verändert. Durch den VRG-Wechsel fallen keine Kosten an.<br>Weiters kann der Wechsel der Veranlagungsgruppe die Anwendung eines anderen Rechnungszinses in der neu gewählten Veranlagungsgruppe zur Folge haben. Dies wirkt sich auf das prognostizierte Kapital sowie die hochgerechnete Pensionshöhe aus.</p>';
        this.title = 'Antrag auf Wechsel der Veranlagungs- und Risikogemeinschaft (VRG)';
        this.teaser = {
            introtext: 'Sie können wählen, welcher VRG Sie angehören möchten. Der Wechsel der VRG kann bis 30.11. des aktuellen Jahres mit Wirksamkeit ab 01.01. des Folgejahres erfolgen, anderenfalls findet der Wechsel zum 01.01. des darauffolgenden Jahres statt.',
            title: 'Wechsel der Veranlagungs- und Risikogemeinschaft (VRG)'
        };
        this.type = 'vrg-change';
        this.detailComponent = () => import('~/portals/rak/components/requests/vrg-change.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/vrg-change.vue');
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
    }
}
