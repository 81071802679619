const sentryPlugin = ({ $sentry, store }) => {
    if (!$sentry) {
        return;
    }
    store.subscribe((mutation) => {
        if (mutation.type === 'user/setUser' || mutation.type === 'user/setUserData') {
            const user = mutation.payload;
            if (!user) {
                $sentry.setUser(null);
                return;
            }
            $sentry.setUser({
                email: user.email,
                id: user.id,
                username: user.full_name
            });
        }
    });
};
export default sentryPlugin;
