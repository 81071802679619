import Request from '~/classes/Request';
import { userStore } from '~/utils/store-accessor';
export default class RAKDeathCompensationRequest extends Request {
    constructor(data) {
        var _a, _b, _c;
        super(data);
        this.introtext = '<p>Gemäß § 41 der Satzung Teil B 2018 können Versicherte für den Fall ihres Todes vor Inanspruchnahme einer Leistung und ohne  Hinterlassung von anspruchsberechtigten Hinterbliebenen, eine <strong>Person bestimmen</strong>, die Anspruch auf Auszahlung einer <strong>einmaligen Abfindung</strong> hat.</p><p>Die Abfindung für den Todesfall beträgt <strong>60 %</strong> der auf den Rentenkonten der oder des verstorbenen Versicherten verbuchten Beträge.</p><p>Die Änderung der ausgewählten Person kann jederzeit vorgenommen werden.</p>';
        this.notificationtext = 'Sie haben kürzlich eine Änderung der Personen bekannt gegeben. Die Aktualisierung im Portal kann einige Tage dauern. Weitere Änderungen sind erst danach wieder möglich.';
        this.title = 'Antrag auf Abfindung im Todesfall';
        this.teaser = {
            title: 'Abfindung im Todesfall',
            introtext: 'Sie haben die Möglichkeit, für den Fall Ihres Ablebens eine begünstigte Person einzusetzen, an die 60 % Ihres Alterskapitals ausbezahlt werden würde.'
        };
        this.type = 'death-compensation';
        this.detailComponent = () => import('~/portals/rak/components/requests/death-compensation.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/death-compensation.vue');
        this.data = (_a = data.data) !== null && _a !== void 0 ? _a : {};
        if (((_b = userStore.user) === null || _b === void 0 ? void 0 : _b.relative_persons.primary) || ((_c = userStore.user) === null || _c === void 0 ? void 0 : _c.relative_persons.secondary)) {
            this.teaser.introtext = 'Sie haben für den Fall Ihres Ablebens folgende begünstigte Person(en) definiert:';
        }
        if (this.recently_defined) {
            this.enabled = false;
        }
    }
    get hasDeadline() {
        if (this.recently_defined) {
            return false;
        }
        return super.hasDeadline;
    }
}
