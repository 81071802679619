var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Page } from '~/classes/App';
import Portal from '~/classes/Portal';
import { $axios, userStore } from '~/utils/store-accessor';
import { API_PATHS, PATHS } from '~/router';
const HOSTS = {};
if (process.env.tenant_host_bpk) {
    HOSTS[process.env.tenant_host_bpk] = 'bpk';
}
if (process.env.tenant_host_pk) {
    HOSTS[process.env.tenant_host_pk] = 'pk';
}
if (process.env.tenant_host_vk) {
    HOSTS[process.env.tenant_host_vk] = 'vk';
}
if (process.env.tenant_host_rak) {
    HOSTS[process.env.tenant_host_rak] = 'rak';
}
if (process.env.tenant_host_wff) {
    HOSTS[process.env.tenant_host_wff] = 'wff';
}
const PORTALS = {
    pk: () => import('~/portals/pk/portal'),
    bpk: () => import('~/portals/bpk/portal'),
    rak: () => import('~/portals/rak/portal'),
    vk: () => import('~/portals/vk/portal')
};
const META_NAV_ITEMS = [
    { text: 'Mein Profil', icon: '$vuetify.icons.person_outline', path: PATHS.PROFILE.INDEX },
    { text: 'Anmelden', icon: '$vuetify.icons.login', path: PATHS.AUTH.LOGIN },
    { text: 'Abmelden', icon: '$vuetify.icons.logout', path: PATHS.AUTH.LOGOUT }
];
export function getHostByKey(key) {
    return Object.keys(HOSTS).find(host => HOSTS[host] === key);
}
let AppStore = class AppStore extends VuexModule {
    constructor() {
        super(...arguments);
        this._portal = new Portal({});
        this._snackbar = {
            content: '',
            color: '',
            visible: false
        };
        this.error = null;
        this.isLoaded = false;
        this.footerNavItems = [];
        this.globalPages = {};
        this.metaNavItems = META_NAV_ITEMS;
    }
    addMetaNavItem(item) {
        this.metaNavItems.unshift(item);
    }
    addFooterNavItem(item) {
        this.footerNavItems.push(item);
    }
    addGlobalPage({ key, page }) {
        Vue.set(this.globalPages, key, page);
    }
    setError(error) {
        this.error = error;
    }
    setIsLoaded(isLoaded) {
        this.isLoaded = isLoaded;
    }
    setMaintenance(maintenance) {
        this._maintenance = maintenance;
    }
    setPortal(portal) {
        this._portal = portal;
    }
    setSnackbar({ content, color, visible }) {
        this._snackbar = {
            content,
            color,
            visible
        };
    }
    async fetchInitialData() {
        await $axios.get(API_PATHS.INITIAL_DATA)
            .then((response) => {
            var _a, _b;
            (_a = response.data.metaNavigation) === null || _a === void 0 ? void 0 : _a.reverse().forEach((item) => {
                this.addMetaNavItem({
                    text: item.title,
                    path: item.slug.charAt(0) === '/' ? item.slug : `/${item.slug}`,
                    icon: (item.icon && item.icon !== 'none') ? `$vuetify.icons.${item.icon}` : '',
                    external_url: item.external_url
                });
            });
            (_b = response.data.footerNavigation) === null || _b === void 0 ? void 0 : _b.forEach((item) => {
                this.addFooterNavItem({
                    text: item.title,
                    path: item.slug.charAt(0) === '/' ? item.slug : `/${item.slug}`,
                    external_url: item.external_url
                });
            });
            if (response.data.globalPages) {
                for (const key in response.data.globalPages) {
                    const page = new Page(response.data.globalPages[key]);
                    this.addGlobalPage({ key, page });
                }
            }
            if (response.data.maintenance) {
                this.setMaintenance(response.data.maintenance);
            }
        }).catch((error) => {
            this.setError(error);
        }).finally(() => {
            this.setIsLoaded(true);
        });
    }
    async initPortal(host) {
        const portalKey = HOSTS[host];
        if (portalKey in PORTALS) {
            const { default: portal } = await PORTALS[portalKey]();
            this.setPortal(portal);
            await this.fetchInitialData();
        }
        else {
            return Promise.reject(new Error('Domain not be found'));
        }
    }
    showSnackbar({ content = '', type }) {
        if (type === 'error' && !content) {
            content = this.store.$i18n.t('ERROR_MESSAGE');
        }
        this.setSnackbar({
            content,
            color: type,
            visible: true
        });
    }
    hideSnackbar() {
        this.setSnackbar({
            content: '',
            color: this._snackbar.color,
            visible: false
        });
    }
    get footerNavigation() {
        return this.footerNavItems.filter(item => !item.disabled);
    }
    get maintenance() {
        return this._maintenance;
    }
    get metaNavigation() {
        for (const item of this.metaNavItems) {
            if (item.path === PATHS.PROFILE.INDEX) {
                item.disabled = !userStore.canAccess;
            }
            else if (item.path === PATHS.AUTH.LOGIN) {
                item.disabled = userStore.userExists;
            }
            else if (item.path === PATHS.AUTH.LOGOUT) {
                item.disabled = !userStore.userExists;
            }
        }
        return this.metaNavItems.filter(item => !item.disabled);
    }
    get portal() {
        return this._portal;
    }
    get snackbar() {
        return this._snackbar;
    }
    get sidebarNavigation() {
        return this._portal.sidebarNavigation.filter(item => !item.disabled);
    }
};
__decorate([
    Mutation
], AppStore.prototype, "addMetaNavItem", null);
__decorate([
    Mutation
], AppStore.prototype, "addFooterNavItem", null);
__decorate([
    Mutation
], AppStore.prototype, "addGlobalPage", null);
__decorate([
    Mutation
], AppStore.prototype, "setError", null);
__decorate([
    Mutation
], AppStore.prototype, "setIsLoaded", null);
__decorate([
    Mutation
], AppStore.prototype, "setMaintenance", null);
__decorate([
    Mutation
], AppStore.prototype, "setPortal", null);
__decorate([
    Mutation
], AppStore.prototype, "setSnackbar", null);
__decorate([
    Action
], AppStore.prototype, "fetchInitialData", null);
__decorate([
    Action
], AppStore.prototype, "initPortal", null);
__decorate([
    Action
], AppStore.prototype, "showSnackbar", null);
__decorate([
    Action
], AppStore.prototype, "hideSnackbar", null);
AppStore = __decorate([
    Module({
        name: 'app',
        stateFactory: true,
        namespaced: true
    })
], AppStore);
export default AppStore;
