import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import options from './vuetify-options';
Vue.use(Vuetify);
const VuetifyPlugin = (ctx) => {
    const vuetify = new Vuetify(options);
    ctx.app.vuetify = vuetify;
    ctx.$vuetify = vuetify.framework;
};
export default VuetifyPlugin;
