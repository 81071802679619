import Request from '~/classes/Request';
export default class RAKSepaRequest extends Request {
    constructor() {
        super(...arguments);
        this.introtext = '<p>Möchten Sie Ihre Beiträge per SEPA-Lastschrift-Mandat bezahlen, füllen Sie bitte das folgende Formular vollständig aus und senden es anschließend unterzeichnet an Ihre Rechtsanwaltskammer.</p>';
        this.title = 'SEPA-Lastschrift-Mandat';
        this.teaser = {
            introtext: 'Mit Erteilung eines SEPA-Lastschrift-Mandats stellen Sie die rechtzeitige Begleichung der Vorschreibungen sicher.',
            title: 'SEPA-Lastschrift-Mandat'
        };
        this.type = 'sepa';
        this.formComponent = () => import('~/portals/rak/components/forms/requests/sepa.vue');
    }
}
