var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from 'nuxt-property-decorator';
import { namespace } from 'vuex-class';
import { PATHS } from '~/router';
import IconCalculation from '~/components/icons/Calculation.vue';
import IconExclamationChatSquare from '~/components/icons/ExclamationChatSquare.vue';
import IconExclamationTriangle from '~/components/icons/ExclamationTriangle.vue';
import AbstractPage from '~/mixins/AbstractPage';
const app = namespace('app');
let Error = class Error extends AbstractPage {
    constructor() {
        super(...arguments);
        this.PATHS = PATHS;
    }
    get iconComponent() {
        switch (this.error.statusCode) {
            case 404:
                return IconExclamationTriangle;
            case 503:
                return IconCalculation;
            default:
                return IconExclamationChatSquare;
        }
    }
    get title() {
        return this.$te(`errors.${this.error.statusCode}.title`) ? this.$tc(`errors.${this.error.statusCode}.title`) : this.$tc('errors.default.title');
    }
    get introText() {
        if (this.error.message === 'MAINTENANCE') {
            if (this.maintenance.app.active && this.maintenance.app.message) {
                return this.maintenance.app.message;
            }
            if (this.$nuxt.context.route.meta) {
                let maintenanceType = '';
                for (let i = 0; i < this.$nuxt.context.route.meta.length; i++) {
                    if (this.$nuxt.context.route.meta[i].maintenanceType) {
                        maintenanceType = this.$nuxt.context.route.meta[i].maintenanceType;
                        break;
                    }
                }
                if (maintenanceType) {
                    return this.maintenance[maintenanceType].message;
                }
            }
        }
        return this.$te(`errors.${this.error.statusCode}.introtext`) ? this.$tc(`errors.${this.error.statusCode}.introtext`) : this.$tc('errors.default.introtext');
    }
};
__decorate([
    Prop([Object])
], Error.prototype, "error", void 0);
__decorate([
    app.State
], Error.prototype, "globalPages", void 0);
__decorate([
    app.Getter
], Error.prototype, "maintenance", void 0);
Error = __decorate([
    Component
], Error);
export default Error;
