var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
let CeGlossary = class CeGlossary extends Vue {
    constructor() {
        super(...arguments);
        this.cssClass = 'ce-glossary';
        this.openedPanelIndex = -1;
    }
    onRouteChanged() {
        this.handleHash();
    }
    mounted() {
        this.handleHash();
    }
    handleHash() {
        if (this.$route.hash) {
            this.openedPanelIndex = this.sections.findIndex((section) => {
                return section.identifier && section.identifier.toLowerCase() === this.$route.hash.substr(1).toLowerCase();
            });
            if (this.openedPanelIndex >= 0) {
                setTimeout(() => {
                    this.$scrollTo(`#glossary-item-${this.openedPanelIndex}`);
                }, 500);
            }
        }
    }
};
__decorate([
    Prop([String])
], CeGlossary.prototype, "title", void 0);
__decorate([
    Prop([Array])
], CeGlossary.prototype, "sections", void 0);
__decorate([
    Watch('$route', { deep: true })
], CeGlossary.prototype, "onRouteChanged", null);
CeGlossary = __decorate([
    Component
], CeGlossary);
export default CeGlossary;
