import Vue from 'vue';
import CeDefault from '~/components/content/elements/CeDefault';
import CeDynamic from '~/components/content/CeDynamic';
import CeCallToAction from '~/components/content/elements/CeCallToAction.vue';
import CeGlossary from '~/components/content/elements/CeGlossary.vue';
import CeRenderer from '~/components/content/CeRenderer';
import CeRegularTextElement from '~/components/content/elements/CeRegularTextElement.vue';
import CeTextCards from '~/components/content/elements/CeTextCards.vue';
import PageLink from '~/components/global/PageLink';
import PageTitle from '~/components/global/PageTitle.vue';
import TextCard from '~/components/card/TextCard';
const components = {
    // Global elements
    PageLink,
    PageTitle,
    TextCard,
    // Content elements
    CeDefault,
    CeDynamic,
    CeCallToAction,
    CeGlossary,
    CeTextCards,
    CeRenderer,
    CeRegularTextElement
};
export default ({ app }) => {
    Object.entries(components).forEach(([key, value]) => {
        Vue.component(key, value);
        if (!app.components) {
            app.components = {};
        }
        app.components[key] = value;
    });
};
