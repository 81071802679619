import Request from '~/classes/Request';
import { i18n, userStore } from '~/utils/store-accessor';
export default class RAKIncomeReductionRequest extends Request {
    constructor(data) {
        var _a, _b;
        super(data);
        this.introtext = '<p>Dieser Antrag ist bis zum <strong>30.06.</strong> für das laufende Kalenderjahr zu stellen. Die Ermäßigung gilt jeweils nur für <strong>ein Kalenderjahr</strong> und ist bei Bedarf im nächsten Jahr erneut zu beantragen.</p>';
        this.title = 'Antrag auf Ermäßigung des Beitrags wegen Einkommens';
        this.teaser = {
            introtext: 'Sie können jährlich eine Ermäßigung des Beitrags auf 20–60 % entsprechend der Einkommens- bzw. Gewinngrenzen beantragen.',
            title: 'Ermäßigung des Beitrags wegen Einkommens'
        };
        this.type = 'income-reduction';
        this.detailComponent = () => import('~/portals/rak/components/requests/income-reduction.vue');
        this.formComponent = () => import('~/portals/rak/components/forms/requests/income-reduction.vue');
        const availableAccounts = ((_a = userStore.user) === null || _a === void 0 ? void 0 : _a.availableAccounts) || [];
        if (availableAccounts.length) {
            this.introtext += `<p>Der <strong>Beitrag</strong> zur Versorgungseinrichtung Teil B beträgt für das Jahr ${availableAccounts[0].jahr_ordentlicher_jahresbeitrag} <strong>${i18n.n(availableAccounts[0].ordentlicher_jahresbeitrag, 'currency')}</strong> p.a..</p>`;
        }
        this.data = (_b = data.data) !== null && _b !== void 0 ? _b : {};
    }
}
