import { appStore } from '~/store';
const matomoPlugin = ({ $matomo, store }) => {
    if (!$matomo) {
        return;
    }
    $matomo.setSiteId(appStore.portal.matomoSiteId);
    store.subscribe((mutation) => {
        if (mutation.type === 'app/setPortal') {
            $matomo.setSiteId(mutation.payload.matomoSiteId);
        }
    });
};
export default matomoPlugin;
