var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Message from '~/classes/Message';
import { $axios, $notifier } from '~/utils/store-accessor';
import { API_PATHS } from '~/router';
/*
 * Messages store
 */
let MessagesStore = class MessagesStore extends VuexModule {
    constructor() {
        super(...arguments);
        this.inbox = [];
        this.sent = [];
        this.numberOfUnreadMessages = 0;
    }
    setInbox(messages) {
        this.inbox = messages;
    }
    setMessageIsRead(message) {
        this.inbox.forEach((inboxMessage) => {
            if ((inboxMessage.id === message.id) && inboxMessage.is_unread) {
                inboxMessage.is_unread = false;
                this.numberOfUnreadMessages = Math.max(this.numberOfUnreadMessages - 1, 0);
            }
        });
    }
    setNumberOfUnreadMessages(count) {
        this.numberOfUnreadMessages = Math.max(count, 0);
    }
    setSent(messages) {
        this.sent = messages;
    }
    async deleteMessage(message) {
        await $axios.delete(API_PATHS.MESSAGE.DELETE.replace(':id', message.root_id))
            .then((response) => {
            var _a;
            $notifier.showMessage({
                content: 'Die Nachricht wurde erfolgreich gelöscht',
                type: 'success'
            });
            if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.number_of_unread_messages) {
                this.setNumberOfUnreadMessages(response.data.number_of_unread_messages);
            }
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
        });
    }
    async fetchInbox() {
        await $axios.get(API_PATHS.MESSAGE.INBOX)
            .then((response) => {
            let messages = [];
            if (response.data) {
                messages = response.data.map((message) => new Message(message));
            }
            this.setInbox(messages);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
            throw error;
        });
    }
    async fetchSent() {
        await $axios.get(API_PATHS.MESSAGE.SENT)
            .then((response) => {
            let messages = [];
            if (response.data) {
                messages = response.data.map((message) => new Message(message));
            }
            this.setSent(messages);
        })
            .catch((error) => {
            var _a;
            $notifier.showMessage({
                content: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.message,
                type: 'error'
            });
            throw error;
        });
    }
    reset() {
        this.setInbox([]);
        this.setSent([]);
        this.setNumberOfUnreadMessages(0);
        return Promise.resolve();
    }
};
__decorate([
    Mutation
], MessagesStore.prototype, "setInbox", null);
__decorate([
    Mutation
], MessagesStore.prototype, "setMessageIsRead", null);
__decorate([
    Mutation
], MessagesStore.prototype, "setNumberOfUnreadMessages", null);
__decorate([
    Mutation
], MessagesStore.prototype, "setSent", null);
__decorate([
    Action
], MessagesStore.prototype, "deleteMessage", null);
__decorate([
    Action
], MessagesStore.prototype, "fetchInbox", null);
__decorate([
    Action
], MessagesStore.prototype, "fetchSent", null);
__decorate([
    Action
], MessagesStore.prototype, "reset", null);
MessagesStore = __decorate([
    Module({
        name: 'messages',
        stateFactory: true,
        namespaced: true
    })
], MessagesStore);
export default MessagesStore;
