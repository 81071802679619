// Component to render CE based on type
import { Vue } from 'nuxt-property-decorator';
export default Vue.extend({
    props: {
        /**
         * Content elements data props
         */
        data: {
            type: [Object, Array],
            required: true,
            default: () => {
                return {};
            }
        },
        /**
         * type of content element
         */
        component: {
            type: String,
            required: true
        }
    },
    render(createElement) {
        var _a;
        // content element components are registered in CamelCase like "CeMyComponent",
        // but the type is coming as "my-component" so we have to transform the name and the tag
        const typeNameCamelCase = this.component.replace(/-([a-z])/g, (g) => {
            return g[1].toUpperCase();
        });
        let elementTag = `ce-${this.component}`;
        const componentName = `Ce${typeNameCamelCase[0].toUpperCase() +
            typeNameCamelCase.slice(1)}`;
        if (!((_a = this.$nuxt.$options.components) === null || _a === void 0 ? void 0 : _a[componentName])) {
            elementTag = 'ce-default';
        }
        return createElement(elementTag, {
            props: this.data
        });
    }
});
